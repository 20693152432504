import { createContext, useState } from 'react';
import { useSetGetDelete } from '../../hooks';
import { keys } from '../../models';


const AppContext = createContext();


const AppContextProvider = ({children}) => {

    const [ selectedMenuItem, setSelectedMenuItem ] = useState('dashboard');

    const [ allPublications, setAllPublications ] = useState([]);
    const [ allHilightPublications, setAllHilightPublications ] = useState([]);

    const [ idEditPublication, setIdEditPublication, deleteIdEditPublication ] = useSetGetDelete(null, keys.EDITINGPUBID);
    const [ editPublication, setEditPublication ] = useState({});
    // const [ editPublication, setEditPublication ] = useSetGetDelete({}, keys.EDITINGPUB);

    const [ pubsPerPage, setPubsPerPage ] = useState(10);
    const [ currentPage, setCurrentPage, ] = useState(1);
    const [ totalPublications, setTotalPublications ] = useState(0);
    const [ totalPages, setTotalPages ] = useState(0);

    const [ previewPubId, setPreviewPubId ] = useState();

    const [ arranges, setArranges ] = useState([]);
    
    return (
        <AppContext.Provider
            value={{
                selectedMenuItem, setSelectedMenuItem,

                allPublications, setAllPublications,
                allHilightPublications, setAllHilightPublications,
                
                idEditPublication, setIdEditPublication, deleteIdEditPublication,
                editPublication, setEditPublication,
                
                pubsPerPage, setPubsPerPage,
                currentPage, setCurrentPage,
                totalPublications, setTotalPublications,
                totalPages, setTotalPages,

                previewPubId, setPreviewPubId,

                arranges, setArranges,
            }}
        >
            {children}
        </AppContext.Provider>
    )
};

export default AppContext;
export { AppContextProvider };
