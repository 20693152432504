import { EditIcon, PlayIcon, PauseIcon, DeleteIcon } from '../../../assets';

import style from './row.module.scss';


const SectionRow = ({i, section, editHandler, playPauseHandler, deleteHandler, theme, user, dragRow, draggedOverRow, handleSort }) => {
    return (
        <div
            className={style.main_row_content}
            style={{ background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}
            draggable
            onDragStart={ () => dragRow.current = i}
            onDragEnter={ () => draggedOverRow.current = i}
            onDragEnd={handleSort}
            onDragOver={(e) => {e.preventDefault()}}
        >
            {/* <p className={`${style.section_id} ${style.small_bold_text}`} style={ section.active ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`}}>{section.position + 1}</p> */}
            <p className={`${style.section_id} ${style.small_bold_text}`} style={ section.active ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`}}>{section.active ? 'on': 'off'}</p>
            <div className={style.section_row}>
                
                <div className={style.section_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.section_title}>
                            <p className={style.big_bold_text} style={{color:`${theme.text}`}}>{section.name}</p>
                        </div>
                    </div>
                    <div className={style.id_title}>
                        <div className={style.section_title}>
                            <p className={style.light_text} style={{color:`${theme.text}`}}>{section.description}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>
                        <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{section.createdAt.split('T')[0]}</p>
                    </div>

                </div>
                
                <div className={style.pub_icons}>
                
                    <div onClick={ () => editHandler(section.id, section.name, section.description) } style={{cursor: 'pointer'}}>
                        <EditIcon width='19px' height='19px' fill={theme.alttext} />
                    </div>
                    {
                        section.active ?
                        <div onClick={() => playPauseHandler(section.id, false)} style={{cursor: 'pointer'}}>
                            <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                        :
                        <div onClick={() => playPauseHandler(section.id, true)} style={{cursor: 'pointer'}}>
                            <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                    }
                    {
                        user.role === 'Admin'
                        &&
                        <div onClick={() => deleteHandler(section.id, section.name)} style={{cursor: 'pointer'}}>
                            <DeleteIcon width='17px' fill={theme.primary}/>
                        </div>
                    }
                </div>
              
            </div>
        </div>
    )
};

export default SectionRow;
