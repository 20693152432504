import style from './upload.module.scss';


const ImageUploader = ( {id, handler} ) => {
    return (
        <>
            <label htmlFor={id}>
                <div className={style.uploadLabel}>+</div>
            </label>
            <input
                className={style.input_upload}
                type="file"
                name={id}
                id={id}
                accept="image/png, image/jpeg, image/jpg, image/webp, image/gif image/heic"
                onChange={(e) => handler(e, id)}
            />
        </>
    )
};

export default ImageUploader;
