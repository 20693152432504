import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../contexts';
import { publicRoutes } from '../../routes';


const AuthGuard = () => {
    const { user, token } = useContext(UserContext);

    return (
        user.id && token ? <Outlet /> : <Navigate replace to={publicRoutes.LOGIN} />
    )
};

export default AuthGuard;


