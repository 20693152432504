import style from './upload.module.scss';


const ImageDeleter = ( {id, imgCompletePath, handleDelete} ) => {

    return (
        <button
            className={`${style.uploadLabel} ${style.delete_button}`}
            style={{backgroundImage: `url(${imgCompletePath})`}}
            onClick={() => handleDelete(id)}
        >
            -
        </button>
    )
};

export default ImageDeleter;