const Quotes = ({width, fill}) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 51.3 40" xmlSpace="preserve" width={width} fill={fill}>
            <g>
                <path d="M0,22.7C0.4,33.5,8.5,39,18.4,40l3-6.9c-3.8-0.7-10.6-3.2-10.2-10.4h12.6V0H0V22.7z"/>
                <path d="M51.3,0H27.6v22.7C28,33.5,36.1,39,46,40l3-6.9c-3.8-0.7-10.6-3.2-10.2-10.4h12.6V0z"/>
            </g>
        </svg>
    );
}

export default Quotes;
