export const LONGMONTH = {
    'ENE': 'Enero',
    'FEB': 'Febrero',
    'MAR': 'Marzo',
    'ABR': 'Abril',
    'MAY': 'Mayo',
    'JUN': 'Junio',
    'JUL': 'Julio',
    'AGO': 'Agosto',
    'SEP': 'Septiembre',
    'OCT': 'Octubre',
    'NOV': 'Noviembre',
    'DIC': 'Diciembre'
};

export const unitConversion = (value) => {
    return value < 999 ? value : `${parseFloat(value * 0.001).toFixed(2)} k`;
};
