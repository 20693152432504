export const publicRoutes = {
    HOME: '/',
    LOGIN: '/login',
};

export const privateRoutes = {
    DASHBOARD: '/dashboard',

    PUBLICATIONS: '/publicaciones',
    NEWPUBLICATION: '/publicaciones/nueva',
    EDITPUBLICATION: '/publicaciones/editar',
    HILIGHTS: '/publicaciones/destacadas',
    LISTPUBLICATIONS: '/publicaciones/lista',
    PREVIEWPUBLICATION: '/publicaciones/preview',
    
    COMPONENTS: '/componentes',
    SECTIONS: '/secciones',
    CATEGORIES: '/categorias',
    TAGS: '/etiquetas',
    
    SIDEBAR: '/sidebar',
    BANNERS: '/sidebar/banners',
    PLAYERS: '/sidebar/players',
    LISTS: '/sidebar/lists',

    ANALITICS: '/analiticas',
    SETTINGS: '/settings',
};
