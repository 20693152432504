import { useContext } from 'react';
import { AppContext, ThemeContext } from '../../contexts';

import { useNavigateTo } from '../../hooks';
import { privateRoutes } from '../../routes';

import { DashboardIcon, PublicationIcon, SectionIcon, CategoryIcon, TagIcon, SidebarIcon, AnaliticsIcon } from '../../assets';

import style from './sidebar.module.scss';


const Sidebar = ({children}) => {

    const { selectedMenuItem, setSelectedMenuItem } = useContext(AppContext);
    const { theme } = useContext(ThemeContext);
    
    const [ goto ] = useNavigateTo();

    return (
        <div className={style.main_container} style={{backgroundColor: `${theme.darkBackground}`}}>
            <div className={style.side_bar} style={{backgroundColor: `${theme.background}`}}>
                
                <div className={selectedMenuItem === 'dashboard' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{ setSelectedMenuItem('dashboard'); goto(privateRoutes.DASHBOARD)}}>
                    <DashboardIcon width="18px" height="18px" fill={selectedMenuItem === 'dashboard' ? theme.primary : theme.placeholder}/>
                    <p
                        style={{color: selectedMenuItem === 'dashboard' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Dashboard
                    </p>
                </div>

    
                <div className={selectedMenuItem === 'publications' ? `${style.sidebar_button_container} ${style.black}` : style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('publications'); goto(privateRoutes.PUBLICATIONS)}}>
                    <PublicationIcon width="18px" height="18px" fill={selectedMenuItem === 'publications' ? theme.primary : theme.placeholder} />
                    <p
                        // style={{color: selectedMenuItem === 'publications' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Publicaciones
                    </p>
                </div>

                <div className={selectedMenuItem === 'components' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('components'); goto(privateRoutes.COMPONENTS)}}>
                    <SectionIcon width="18px" height="18px" fill={selectedMenuItem === 'components' ? theme.primary : theme.placeholder} />
                    <p
                        // style={{color: selectedMenuItem === 'sections' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Componentes
                    </p>
                </div>

                <div className={selectedMenuItem === 'sections' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('sections'); goto(privateRoutes.SECTIONS)}}>
                    <SectionIcon width="18px" height="18px" fill={selectedMenuItem === 'sections' ? theme.primary : theme.placeholder} />
                    <p
                        // style={{color: selectedMenuItem === 'sections' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Secciones
                    </p>
                </div>

                <div className={selectedMenuItem === 'categories' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('categories'); goto(privateRoutes.CATEGORIES)}}>
                    <CategoryIcon width="18px" height="18px" fill={selectedMenuItem === 'categories' ? theme.primary : theme.placeholder} />
                    <p
                        // style={{color: selectedMenuItem === 'categories' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Categorías
                    </p>
                </div>

                <div className={selectedMenuItem === 'tags' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('tags'); goto(privateRoutes.TAGS)}}>
                    <TagIcon width="18px" height="18px" fill={selectedMenuItem === 'tags' ? theme.primary : theme.placeholder} />
                    <p 
                        // style={{color: selectedMenuItem === 'tags' ? 'white' : `${theme.alttext}`}} 
                        className={style.sidebar_button}
                        >
                        Etiquetas
                    </p>
                </div>

                <div className={selectedMenuItem === 'sidebar' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('sidebar'); goto(privateRoutes.SIDEBAR)}}>
                    <SidebarIcon width="18px" height="18px" fill={selectedMenuItem === 'sidebar' ? theme.primary : theme.placeholder} />
                    <p 
                        // style={{color: selectedMenuItem === 'tags' ? 'white' : `${theme.alttext}`}} 
                        className={style.sidebar_button}
                        >
                        Barra lateral
                    </p>
                </div>

                <div className={selectedMenuItem === 'analitics' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('analitics'); goto(privateRoutes.ANALITICS)}}>
                    <AnaliticsIcon width="18px" height="18px" fill={selectedMenuItem === 'analitics' ? theme.primary : theme.placeholder} />
                    <p 
                        // style={{color: selectedMenuItem === 'tags' ? 'white' : `${theme.alttext}`}} 
                        className={style.sidebar_button}
                        >
                        Analíticas
                    </p>
                </div>

            </div>

            <div className={style.content}>
                {children}
            </div>
        </div>
    )
};

export default Sidebar;
