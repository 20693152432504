import {  useState, useEffect, useRef, useContext } from 'react';
import config from '../../../config';

import { AppContext, ThemeContext, UserContext, ModalContext } from '../../../contexts';
import { useNavigateTo } from '../../../hooks';

import { MainLayOut } from '../../../layouts';
import { privateRoutes } from '../../../routes';

import { AddIcon, EditIcon, Loader } from '../../../assets';

import Bannercard  from './components/bannercard';
import ToggleSwitch from '../toggleswitch';

import { updateSidebarContentAPI, getAllBannersAPI, uploadBannerAPI, deleteBannerByIdAPI, updateBannerByIdAPI, updateBannersOrderAPI } from '../../../services';

import style from './banners.module.scss';
import { error_type } from '../../../models';


const Banners = () => {

    const { user, deleteUser, token, deleteToken } = useContext(UserContext);
    const { arranges, setArranges } = useContext(AppContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);
    const [ goTo ] = useNavigateTo();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ banners, setBanners ] = useState([]);

    const defaultArrangeState = () => { return arranges.filter(arrange => arrange.name === 'banners')[0] || {}; };
    const [ bannersArrange, setBannersArrange ] = useState(defaultArrangeState);

    const [ titleEdition, setTitleEdition ] = useState(false);

    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ idToDelete, setIdToDelete ] = useState(null);

    const dragCard = useRef(0);
    const draggedOverCard = useRef(0);
    const arrangeCardsTimer = useRef(null);


    const uploadImage = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('enctype','multipart/form-data');
        formData.append('banner', file);

        uploadBannerAPI(formData, token)
        .then(res => {
            if (res.error) {
                formData.delete('banner');
                if (res.errortype === error_type.AUTH) {
                    deleteToken();
                    deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                formData.delete('banner');
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setBanners(prev => {
                const newData = [...prev, res.data];
                return newData;
            });
            e.target.files = null;
            formData.delete('banner');
            return;
        });
    };
    const updateBannersOrder = (data) => {
        clearTimeout(arrangeCardsTimer.current)

        arrangeCardsTimer.current = setTimeout( () => {
            updateBannersOrderAPI(data, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser();
                        deleteToken();
                        setIdToDelete(null);
                        activateNotification(`${res.message}`, 'error', true, 3800);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 3800);
                setBanners(res.data)
                return;
            })
        }, 1300);
    };
    const handleSortCards = () => {
        const cardsClone = [...banners];
        const temp = cardsClone[dragCard.current];
        cardsClone[dragCard.current] = cardsClone[draggedOverCard.current];
        cardsClone[draggedOverCard.current] = temp;
        const sorted = cardsClone.map( (card, index) => {
            return { id: card.id, position: index };
        });
        updateBannersOrder(sorted);
        setBanners(cardsClone);
    };
    const openDeleteModal = (id) => {
        setIdToDelete(id);
        setDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setIdToDelete(null);
        setDeleteModal(false);
    };
    const handleDeleteBanner = () => {
        deleteBannerByIdAPI(idToDelete, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    setDeleteModal(false);
                    activateNotification(`${res.message}`, 'error', true, 3400);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3400);
                setDeleteModal(false);
                setIdToDelete(null);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3400);
            setDeleteModal(false);
            setBanners(res.data);
            setIdToDelete(null);
            return;
        })
    };
    const activateBanner = (id, status) => {
        const data = { active: status };

        updateBannerByIdAPI(id, data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            };
            const newBanners = [...banners];
            const activated = banners.findIndex( item => item.id === id);
            newBanners[activated].active = status;
            setBanners(newBanners);
            activateNotification(`${res.message}`, 'ok', true, 3800);
            return;
        })
    };
    const editBannerLink = (id, link) => {
        const data = { link };

        updateBannerByIdAPI(id, data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            };
            setBanners(res.data);
            activateNotification(`${res.message}`, 'ok', true, 3800);
            return;
        })
    };


    const handleEditTitle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setBannersArrange( prev => {
            const newData = {...prev};
            newData[name] = value;
            return newData;
        })
    };
    const cancelTitleEdition = () => {
        setTitleEdition(false);
        setBannersArrange(defaultArrangeState); 
    };
    const handleChangeArrangeTitleStatus = (id, status) => {
        const data = {
            id,
            showTitle: status
        };
        updateSidebarContentAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setBannersArrange( prev => {
                const newData = {...prev};
                newData.showTitle = status;
                return newData;
            });
            return;
        });
    };
    const handleChangeTitle = () => {
        const id = bannersArrange.id;
        const data = {
            title: bannersArrange.title
        };
        updateSidebarContentAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setTitleEdition(false);
            return;
        });
    };


    useEffect(() => {
        setIsLoading(true);
        getAllBannersAPI(token)
        .then( res => {
            setBanners(res.data);
            setIsLoading(false);
        })
    },[]);


    return (
        <MainLayOut>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <>
                    <div className={style.title_container}>
                        <p className={style.main_title} style={{cursor: 'pointer', color:`${theme.text}`}} onClick={()=> goTo(privateRoutes.SIDEBAR)}>
                            Barra lateral
                            <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Banners</span>
                        </p>
                    </div>

                    <label htmlFor='banneruploader' className={style.block} style={{background: `${theme.background}`}}>
                        <AddIcon width='22px' height='22px' fill={theme.strokeColor02} />
                        <input
                            style={{display:'none'}}
                            type="file"
                            id='banneruploader'
                            accept="image/png, image/jpeg, image/jpg, image/webp, image/gif image/heic"
                            onChange={(e) => uploadImage(e)}
                        />
                        <p className={style.light_text} style={{color: `${theme.text}`}}>Crear nuevo banner</p>
                    </label>

                    <div className={style.block_subtitle} style={{color:`${theme.text}`}}>Título general del bloque</div>
                    <div className={style.title_block} style={{justifyContent:'flex-start',background: `${theme.background}`}}>
                    {
                        titleEdition
                        ?
                        <>
                            <input className={style.input} onChange={handleEditTitle} name="title" value={bannersArrange.title || ''}/>
                            <div className={style.edit_buttons}>
                                <button className={style.cancel} onClick={cancelTitleEdition}>cancelar</button>
                                <button className={style.submit} onClick={handleChangeTitle}>aceptar</button>
                            </div>
                        </>
                        :
                        <div style={{display:'flex',flexDirection:'row',gap:'14px'}}>
                            <ToggleSwitch id={bannersArrange.id} handleChange={handleChangeArrangeTitleStatus} status={bannersArrange.showTitle}/>
                            <div className={style.title_and_icon} onClick={()=>setTitleEdition(true)}>
                                <p onClick={()=>setTitleEdition(true)} className={style.light_text} style={{color: `${theme.text}`}} dangerouslySetInnerHTML={ {__html: bannersArrange?.title || 'Sin Título. Click para editar'} } />
                                <EditIcon width='22px' height='22px' fill={theme.strokeColor01}/>
                            </div>
                        </div>
                    }
                </div>


                    <div className={style.block_subtitle} style={{color:`${theme.text}`}}>Banners</div>
                    <div className={style.banners_list}>
                        {
                            banners?.length
                            ?
                            banners.map( ({ id, image, link, active }, i) => {
                                return (
                                    <Bannercard key={id} i={i} id={id} image={image} link={link} active={active} path={config.api_profile} theme={theme} deleteHandler={openDeleteModal} dragCard={dragCard} draggedOverCard={draggedOverCard} sortHandler={handleSortCards} activateHandler={activateBanner} editHandler={editBannerLink} />
                                )
                            })
                            :
                            <></>
                        }
                    </div>

                    <div className={deleteModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                        <div className={deleteModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>
                
                            <p className={style.text} style={{color:'#EEE',textAlign:'center', margin:'8px'}}>{`Borrar el banner ${idToDelete}?`}</p>
                            <div className={style.modal_openclose_buttons}>
                                <button onClick={handleDeleteBanner}className={style.submit}>ACEPTAR</button>
                                <div className={style.close_button} onClick={closeDeleteModal}></div>
                            </div>

                        </div>
                    </div>

                </>
            }
        </MainLayOut>
    );
}

export default Banners;
