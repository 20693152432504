import { useState, useEffect, useRef, useContext } from 'react';

import { AppContext, UserContext, MenuContext, ModalContext, ThemeContext } from '../../contexts';

import { MainLayOut } from '../../layouts';
import SectionRow from './components/sectionrow';
import { AddIcon } from '../../assets';

import { createNewSectionAPI, getAllSectionsAPI, updateSectionAPI, deleteSectionAPI, arrangeSectionsAPI } from '../../services';

import style from './sections.module.scss';
import { error_type } from '../../models';


const Sections = () => {

    const { user, token, deleteToken, deleteUser } = useContext(UserContext);
    const { setTotalPublications, setTotalPages, setAllHilightPublications, setCurrentPage } = useContext(AppContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);
    const { sections, saveSections } = useContext(MenuContext);


    const [ createModal, setCreateModal ] = useState(false);
    const [ editModal, setEditModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    

    const [ nameEdit, setNameEdit ] = useState('');
    const [ descriptionEdit, setDescriptionEdit ] = useState('');
    const [ idToEdit, setIdToEdit ] = useState(null);
    const [ idToDelete, setIdToDelete ] = useState(null);
    const [ nameToDelete, setNameToDelete ] = useState('');


    const dragRow = useRef(0);
    const draggedOverRow = useRef(0);
    const arrangeRowsTimer = useRef(null);


    const updateSectionsOrder = (data) => {
        activateNotification('Reordenando secciones', 'time', true);
        clearTimeout(arrangeRowsTimer.current);

        arrangeRowsTimer.current = setTimeout( () => {
            arrangeSectionsAPI(data, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(); deleteToken(); setTotalPublications(0);
                        setTotalPages(1);
                        setAllHilightPublications([]);
                        setCurrentPage(1);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    };
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 3000);
                saveSections(res.data);
            })
        }, 1700);
    };

    const handleSortRows = () => {
        const rowsClone = [...sections];
        const temp = rowsClone[dragRow.current];
        rowsClone[dragRow.current] = rowsClone[draggedOverRow.current];
        rowsClone[draggedOverRow.current] = temp;
        const sorted = rowsClone.map( (row, index) => {
            return { id: row.id, position: index };
        });
        updateSectionsOrder(sorted);
        saveSections(rowsClone);
    };

    const openEditModal = (id, name, description) => {
        setEditModal(true);
        setNameEdit(name);
        setDescriptionEdit(description);
        setIdToEdit(id);
    };
    const closeEditModal = () => {
        setEditModal(false);
        setNameEdit('');
        setDescriptionEdit('');
        setIdToEdit(null);
    };
    const openCreateModal = (id) => {
        setCreateModal(true);
    };
    const closeCreateModal = () => {
        setCreateModal(false);
    };
    const handleNewSection = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const description = e.target.description.value;
        if (!name || !description) {
            activateNotification('Por favor, ingresá un título y una descripción para la sección', 'error', true, 4000);
            return;
        }
        const data = {
            name,
            description
        };
        createNewSectionAPI(data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const newData = [...sections, res.data];
            saveSections(newData);
            e.target.name.value = '';
            e.target.description.value = '';
            closeCreateModal();
            return;
        })

    };
    const handleEditSection = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const description = e.target.description.value;
        const data = {
            name,
            description,
            id: idToEdit
        };
        updateSectionAPI(data, idToEdit, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            saveSections(res.data);
            e.target.name.value = '';
            e.target.description.value = '';
            closeEditModal();
            return;
        })
    };
    const playPauseSection = (id, param) => {
        const data = {
            active: param
        };
        updateSectionAPI(data, id, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            saveSections(res.data);
            closeEditModal();
            return;
        })
    };
    const openDeleteModal = (id, name) => {
        setIdToDelete(id);
        setNameToDelete(name);
        setDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setIdToDelete(null);
        setDeleteModal(false);
    };
    const handleDeleteSection = () => {
        deleteSectionAPI(idToDelete, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    setIdToDelete(null);
                    setDeleteModal(false);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const filtered = sections.filter( item => item.id !== idToDelete);
            saveSections(filtered);
            setIdToDelete(null);
            setDeleteModal(false);
            return;
        })
    };

    useEffect(() => {
        getAllSectionsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            saveSections(res.data);
            return;
        })
    },[]);
    

    return (
        <MainLayOut>
            <div className={style.title_container}>
                <p className={style.main_title} style={{color:`${theme.text}`}}>Secciones</p>
            </div>

            <div onClick={openCreateModal} className={style.block} style={{background: `${theme.background}`}}>
                <AddIcon width='22px' height='22px' fill={theme.strokeColor02} />
                <p className={style.light_text} style={{color: `${theme.text}`}}>Crear nueva sección</p>
            </div>

            <div className={style.sections_list}>
                {
                    sections?.map( (section, i) => {
                        return (
                            <SectionRow key={section.id} i={i} section={section} editHandler={openEditModal} playPauseHandler={playPauseSection} deleteHandler={openDeleteModal} theme={theme} user={user} dragRow={dragRow} draggedOverRow={draggedOverRow} handleSort={handleSortRows} />
                        )
                    })
                }
            </div>
            <div className={createModal ? `${style.modal_edition} ${style.blo} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                
                <div className={createModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                    
                    <form className={style.form} onSubmit={handleNewSection}>
                        <div className={style.block}>
                            <p className={style.bold_text}>Título</p>
                            <textarea className={`${style.textarea} ${style.big_input}`} name="name" />
                        </div>

                        <div className={style.block}>
                            <p className={style.bold_text}>Descripción</p>
                            <textarea className={style.textarea} name="description" />
                        </div>
                        <button type="submit" className={style.submit}>aceptar</button>
                    </form>

                    <div className={style.close_button} onClick={closeCreateModal}></div>

                </div>
            </div>

            <div className={editModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                
                <div className={editModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                    
                    <form onSubmit={handleEditSection} className={style.form}>
                        <div className={style.block}>
                            <p className={style.bold_text}>Título</p>
                            <textarea className={`${style.textarea} ${style.big_input}`} onChange={(e) => setNameEdit(e.target.value)} name="name" value={nameEdit || ""}/>
                        </div>

                        <div className={style.block}>
                            <p className={style.bold_text}>Descripción</p>
                            <textarea className={style.textarea} onChange={(e) => setDescriptionEdit(e.target.value)} name="description" value={descriptionEdit || ''} />
                        </div>
                        <button type="submit" className={style.submit}>guardar cambios</button>
                    </form>

                    <div className={style.close_button} onClick={closeEditModal}></div>

                </div>
            </div>

            <div className={deleteModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                
                <div className={deleteModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>
            
                    {/* <p className={style.text} style={{color:'#EEE',textAlign:'center', margin:'60px'}}>Borrar la sección con id {idToDelete}?</p> */}
                    <p className={style.text} style={{color:'#EEE',textAlign:'center', margin:'8px'}}>Borrar la sección "{nameToDelete}"?</p>
                    <div className={style.modal_openclose_buttons}>
                        {/* <button className={style.cancel}>canclar</button> */}
                        <button onClick={handleDeleteSection}className={style.submit}>ACEPTAR</button>
                        <div className={style.close_button} onClick={closeDeleteModal}></div>
                    </div>

                </div>
            </div>
        </MainLayOut>
    )
};

export default Sections;
