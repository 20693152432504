import { ImageUploader, ImageDeleter } from '../../../../assets';

import style from './editor.module.scss';


const ImageDeleteGallery = ({ id, imageCompletePath, deleteHandler, theme}) => {

    return (
        <div className={style.image_gallery_deleter} style={{background: `${theme.background}`}}>

            <div className={style.title_content}>
                <ImageDeleter id={id} imgCompletePath={imageCompletePath} handleDelete={deleteHandler} />
            </div>

        </div>
    )
};

export default ImageDeleteGallery;
