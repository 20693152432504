import style from './editor.module.scss';

const Infoitem = ({ title, data, theme }) => {
    return (
        <div className={style.alt_label}>
            <p className={style.small_light_text} style={{color:`${theme.text}`}}>{title}</p>                                        
            <p className={style.mid_bold_text} style={{color:`${theme.text}`}}>{data}</p>
        </div>
    )
};

export default Infoitem;
