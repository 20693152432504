import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const getAllComponentsAPI = async (token) => {
    try {
        const allComponents = await fetch(`${apipath}/admin/components/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
        })
        return await allComponents.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const createNewComponentAPI = async (data, token) => {
    try {
        const newComponent = await fetch(`${apipath}/admin/components/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await newComponent.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
}

const updateComponentAPI = async (data, id, token) => {
    try {
        const updated = await fetch(`${apipath}/admin/components?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await updated.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const deleteComponentAPI = async (id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/components?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        })
        return await deleted.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const arrangeComponentsAPI = async (data, token) => {
    try {
        const arranged = await fetch(`${apipath}/admin/components/arrange`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await arranged.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};



export { createNewComponentAPI, getAllComponentsAPI, updateComponentAPI, deleteComponentAPI, arrangeComponentsAPI };