import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../contexts';
import { privateRoutes } from '../../routes';

const NotEditingGuard = () => {
    const { idEditPublication } = useContext(AppContext);

    return (
        !idEditPublication
        ?
        <Outlet />
        :
        <Navigate replace to={privateRoutes.EDITPUBLICATION} />
    )
};

export default NotEditingGuard;
