import { useState, useEffect, useContext, useRef } from 'react';

import { AppContext, ModalContext, ThemeContext, UserContext } from '../../contexts';

import { Loader } from '../../assets';
import { MainLayOut } from "../../layouts";

import ArrangeCard from './cards';
import { getSidebarArrangementAPI, arrangeSidebarContentAPI } from '../../services';

import style from './sidebar.module.scss';
import { error_type } from '../../models';



const Sidebar = () => {

    const { arranges, setArranges } = useContext(AppContext);
    const { theme } = useContext(ThemeContext);
    const { user, deleteUser, token, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);

    const dragCard = useRef();
    const draggedOverCard = useRef();
    const arrangeCardsTimer = useRef(null);

    const [ isLoading, setIsLoading ] = useState(false);

    const handleSortCards = () => {
        const cardsClone = [...arranges];
        const temp = cardsClone[dragCard.current];
        cardsClone[dragCard.current] = cardsClone[draggedOverCard.current];
        cardsClone[draggedOverCard.current] = temp;
        const sorted = cardsClone.map( (card, index) => {
            return { id: card.id, position: index}
        });
        handleRearrangeSidebarItems(sorted);
        setArranges(cardsClone);
    };
    const handleRearrangeSidebarItems = (data) => {
        clearTimeout(arrangeCardsTimer.current);

        arrangeCardsTimer.current = setTimeout( () => {
            arrangeSidebarContentAPI(data, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        deleteUser();
                        deleteToken();
                        return;
                    };
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'ok', true, 4000);
                setArranges(res.data);
                return;
            })
        }, 1200);
    };

    useEffect( () => {
        setIsLoading(true);
        getSidebarArrangementAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser();
                    deleteToken();
                    return;
                };
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            setArranges(res.data);
            setIsLoading(false);
            return;
        });
    },[]);


    return (
        <MainLayOut>
            <div className={style.title_container}>
                <p className={style.main_title} style={{color:`${theme.text}`}}>Barra lateral</p>
            </div>
            <>
                {
                    isLoading
                    ?
                    <Loader background={theme.alphaBackground}/>
                    :
                    <div className={style.blocks_container}>
                        {
                            arranges?.length
                            ?
                            arranges.map( (item, i) => {
                                return <ArrangeCard key={item.id} i={i} item={item} dragCard={dragCard} draggedOverCard={draggedOverCard} handleSort={handleSortCards} theme={theme}/>
                            })
                            :
                            <></>          
                        }
                    </div>
                }
            </>
        </MainLayOut>
    );
}

export default Sidebar;
