import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useState, useContext } from 'react';
import { UserContext } from '../../../../contexts';

import style from './editor.module.scss';


const TextEditor = ({ title, value, modifier, htmlModifier,param, theme}) => {

    const { user, token } = useContext(UserContext);

    const [ editSelector, setEditSelector ] = useState(true);

    const modules = {
        toolbar: [ [{ header: [2, 3, false]}],
        ["bold", "italic"], ["link"],
    // ['image']
    ] };


    return (
        <>
        {/* { user.roleId === 1 && */}
            <button className={style.submit} onClick={() => setEditSelector(!editSelector)}>{editSelector ? 'editor html' : 'editor texto'}</button>
        {/* } */}
            {
                editSelector
                ?
                <div className={style.main_text_editor_container}>
                    <div className={style.quill_group} style={{background: `${theme.inputBackground}`}}>                               
                        <ReactQuill
                            className={style.quill_textarea}
                            modules={modules}
                            value={value}
                            onChange={(e) => modifier(e, param)}
                        ></ReactQuill>
                    </div>
                </div>
                :
                <div>
                    <textarea
                        className={style.textarea}
                        name={param}
                        value={value}
                        onChange={(e) => htmlModifier(e)}
                    ></textarea>
                </div>
            }
        </>
    )
};


export default TextEditor;
