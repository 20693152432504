import { Quotes, EndQuotes } from './quotes';

import style from '../preview.module.scss';


const Block = ({ paragraph, order, image, path, folder, epigraph, audiogallery, videogallery }) => {

    return (
        <>
            {  
                order === 1
                ?
                paragraph && <div className={style.text}><span className={style.capital} dangerouslySetInnerHTML={{__html: `${paragraph.slice(3,4)}`}} /><span dangerouslySetInnerHTML={{__html: paragraph.slice(4,)}} /></div>
                :
                paragraph && <div className={style.text} dangerouslySetInnerHTML={{__html: paragraph}} />
            }

            { image || epigraph ?
                <div className={style.image_epigraph_block}>
                    {   image && <img className={style.image} src={`${path}/${folder}/${image}`} alt='gacetamarinera.com.ar, el sitio oficial de noticias de la Amrada Argentina' /> }
                    {
                        epigraph?.length ?
                        <>
                            {
                                epigraph[0] === '#'
                                ?
                                <div className={style.dest} style={ image && {marginTop: '60px'}}>
                                    <Quotes width='34px' fill='#ff6000'/>
                                    {epigraph.replaceAll('#','')}
                                    <EndQuotes width='34px' fill='#ff6000' />
                                </div>
                                :
                                <div className={style.epigraph}>{epigraph}</div>
                            }
                        </>
                        :
                        <></>
                    }
                </div>
                :
                <></>
            }
            
            {
                audiogallery?.map( audio => {
                    return (
                        <>
                        {
                            audio.position === order
                            ?
                            <div key={audio.id} className={style.video_content}>
                                {
                                    audio.type === 'iframe'
                                    ?
                                    <>
                                        { audio.title ? <div className={style.tuition}>{audio.title}</div> : <></> }
                                        <iframe
                                            style={{border:'none',borderRadius:'12px'}}
                                            src={audio.source}
                                            width="100%"
                                            height="250px"
                                            allowFullScreen
                                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                            loading="lazy"
                                        >
                                        </iframe>
                                        { audio.description ? <div className={style.media_dewscription}>{audio.description}</div> : <></> }
                                    </>
                                    :
                                    <></>
                                }
                                {
                                    audio.type === 'file'
                                    ?
                                    <>
                                        { audio.title ? <div className={style.tuition}>{audio.title}</div> : <></> }
                                        <audio style={{width:'100%'}} src={`${path}/${audio.source}`} controls></audio>
                                        { audio.description ? <div className={style.media_dewscription}>{audio.description}</div> : <></> }

                                    </>
                                    :
                                    <></>
                                }
                            </div>
                            :
                            <></>
                        }
                        </>
                    )
                })
            }

            {
                videogallery?.map( video => {
                    return (
                        <>
                            {
                                video.position === order
                                ?
                                <div key={video.id} className={style.video_content}>
                                    { video.title ? <div className={style.tuition}>{video.title}</div> : <></> }
                                    <iframe
                                        // className={style.responsive_iframe}
                                        width='100%'
                                        height='300px'
                                        src={`https://www.youtube.com/embed/${video.source}`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen>
                                    </iframe>
                                    { video.description ? <div className={style.media_dewscription}>{video.description}</div> : <></> }
                                </div>
                                :
                                <></>
                            }
                        </>
                    )
                })
            }
        </>
    );
}

export default Block;

// {/* <div className={style.dest}>
//     <p>
//         <span className={style.colon}>«&emsp;</span>
//         {parse(epigraph.replaceAll('#',''))}
//         <span className={style.colon}>&emsp;»</span>
//     </p>
// </div> */}

