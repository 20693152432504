import style from './circle.module.scss';


const Circle = ({ strokeColor, strokeWidth = 1, width, height, value = 100}) => {
    return (
        <svg viewBox="0 0 36 36" stroke={strokeColor} width={width} height={height}>
            <g className={style.ring_container}>
                <circle strokeWidth={strokeWidth} r={16} className={style.ring_background} />
                <circle strokeWidth={strokeWidth} r={16} className={style.ring_completed} strokeDasharray={`${value}, 100`} />
            </g>
        </svg>
    )
};

export default Circle;