import style from './toggle.module.scss';


const ToggleSwitch = ({ id, handleChange, status}) => {
    return (
        <label className={style.switch}>
            <input
                type="checkbox"
                checked={status ? true : false}
                onChange={() => handleChange(id, !status)}
            />
            <span className={style.slider}></span>
        </label>
    );
}

export default ToggleSwitch;
