import { useState, useEffect, useContext } from 'react';
import { UserContext, ModalContext, ThemeContext } from '../../contexts';

import { MainLayOut } from "../../layouts";
import { Circle, Loader, SmallLoader } from "../../assets";

import { unitConversion, LONGMONTH} from './components';

import { useNavigateTo } from '../../hooks';

import { getYearViewsAPI, getMonthViewsAPI, getEveryMonthViews, getEveryDayViews, getWeekViewsAPI, getTodayViewsAPI } from '../../services';
import { error_type } from '../../models';

import style from './analitics.module.scss';



const Analitics = () => {

    const { token, deleteUser, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);

    const [ goto ] = useNavigateTo()

    const [ yearVisits, setYearVisits ] = useState(0);
    const [ monthVisits, setMonthVisits ] = useState(0);
    const [ weekVisits, setWeekVisits ] = useState(0);
    const [ last7Days, setLast7Days ] = useState(0);
    const [ last30Days, setLast30Days ] = useState(0);
    const [ todayVisits, setTodayVisits ] = useState(0);
    const [ everyParamVisits, setEveryParamVisits ] = useState([]);

    const [ xAxis, setXAxis ] = useState(0);
    const [ yAxis, setYAxis ] = useState(0);
    const [ scroll, setScroll ] = useState(0);

    const [ modal, setModal ] = useState({})

    const [ isLoading , setIsLoading ] = useState(false);
    const [ isLoadingStatis, setIsLoadingStatics ] = useState(false);

    const handleTrackMouse = (e, key) => {
        // console.log(key[0])
        // console.log(modal)
        setXAxis(e.clientX)
        setYAxis(e.clientY)
    };

    const handleStatics = (e) => {
        if (e.target.value === 'months') {
            setIsLoadingStatics(true);
            getEveryMonthViews(token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(); deleteToken();
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        return;
                    }
                    setIsLoadingStatics(false);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                setEveryParamVisits(res.data);
                setIsLoadingStatics(false);
                return;
            });
        };
        if (e.target.value === 'days') {
            setIsLoadingStatics(true);
            getEveryDayViews(token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(); deleteToken();
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        return;
                    }
                    setIsLoadingStatics(false);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                setEveryParamVisits(res.data);
                setIsLoadingStatics(false);
                return;
            });
        };
        return;
    };

    useEffect( () => {
        getYearViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setYearVisits(res.data);
            return;
        });
        getMonthViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setMonthVisits(res.data);
            return;
        });
        getWeekViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setWeekVisits(res.data.lastWeek);
            setLast7Days(res.data.lastSeven);
            setLast30Days(res.data.lastThirty);
            return;
        });
        getTodayViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setTodayVisits(res.data);
            return;
        });
        window.onscroll = () => { setScroll(window.scrollY); };
    },[]);


    return (

        <MainLayOut>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <>
                    <div className={style.title_container} style={{position:'relative'}}>
                    <p className={style.main_title} style={{color:`${theme.text}`}}>Analíticas</p>
                    </div>

                    <div className={style.blocks_container}>
                        <p className={style.light_subtitle} style={{color:`${theme.text}`}}>
                            Estadísticas de visitas
                        </p>
                    </div>

                    <div className={style.blocks_container}>
                        <div className={style.block} style={{flexDirection:'column', background:`${theme.background}`}}>
                            <p style={{color:`${theme.text}`}}>TOTAL DE VISITAS EN {everyParamVisits.searchCriteria}</p>
                            <select className={`${style.selector} ${style.big_selector}`} onChange={handleStatics}>
                                <option defaultValue value={null}>seleccionar</option>
                                <option value="months">por meses</option>
                                <option value="days">por dias</option>
                            </select>
                            {
                                isLoadingStatis
                                ?
                                <SmallLoader />
                                :
                                everyParamVisits?.views?.length
                                ?
                                <div className={style.bar_graph}>
                                
                                    <div className={style.month_day_bar}>
                                        <p className={style.value} style={{color:`${theme.text}`}}>{unitConversion(everyParamVisits.totalViews)}</p>
                                        <div className={style.bar} style={{height:'200px', backgroundColor: `${theme.strokeColor01}`}}></div>
                                        <p className={style.key} style={{color:`${theme.text}`}}>{everyParamVisits.totalCriteria}</p>
                                    </div>
                                    {
                                        everyParamVisits?.views?.map( (item, i) => {
                                            const key = Object.keys(item);
                                            const value = Object.values(item);
                                            return (
                                                <>
                                                    <div key={i} className={style.month_day_bar} style={{cursor: 'pointer'}} onMouseMove={(e) => {handleTrackMouse(e, key), setModal({[key]: true })}} onMouseLeave={() => {setModal({[key]: false })} }>
                                                        <p className={style.value} style={{color:`${theme.text}`}}>{unitConversion(value)}</p>
                                                        <div className={style.bar} style={{height:`${value * 200 / everyParamVisits.totalViews}px`, backgroundColor: `${theme.barColor[i]}`}}></div>
                                                        <p className={style.key} style={{color:`${theme.text}`}}>{key}</p>
                                                           {
                                                            modal[key]
                                                            &&
                                                            <div className={style.month_day_popup} style={{top: `${yAxis-380+scroll}px`, left:`${xAxis-370}px`}}>
                                                                <p className={style.key_text}>{everyParamVisits.type === 'MES' ? `Mes de ${LONGMONTH[key]} de ${everyParamVisits.searchCriteria}` : everyParamVisits.type === 'DÍA' && `Día ${key} de ${everyParamVisits.searchCriteria}`}</p>
                                                                <p className={style.value_text}>Cantidad de visitas: <span>{unitConversion(value)}</span></p>
                                                            </div>
                                                            }
                                                    </div>
                                                </>
                                            )
                                        })        
                                    }
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>


                    {/* ********** GRAFICOS DE ANILLOS ************** */}
                    {/* ********** GRAFICOS DE ANILLOS ************** */}

                    
                    <div className={style.blocks_container}>
                        {/* ********** AÑO Y MES ************** */}
                        
                        <div className={style.block} style={{background:`${theme.background}`}}>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        100%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor01} value='100'/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas del año:&emsp;
                                    <span className={style.mid_bold_text}>{yearVisits}</span>
                                </p>
                            </div>

                            
                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        {Math.floor(monthVisits * 100 / yearVisits)}%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor02} value={Math.round(monthVisits * 100 / yearVisits)}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas mensuales:&emsp;
                                    <span className={style.mid_bold_text}>{monthVisits}</span>
                                </p>
                            </div>

                        </div>
                      
                        {/* ********** MES Y SEMANA *********** */}
                        <div className={style.block} style={{background:`${theme.background}`}}>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        100%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor01} value={100}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Últimos 30 días:&emsp;
                                    <span className={style.mid_bold_text}>{last30Days}</span>
                                </p>
                            </div>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        {Math.floor(last7Days * 100 / last30Days) || 0}%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor03} value={Math.floor(last7Days * 100 / last30Days)}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Últimos 7 días:&emsp;
                                    <span className={style.mid_bold_text}>{last7Days}</span>
                                </p>
                            </div>

                        </div>

                        {/* ********** SEMANA Y DIA *********** */}
                        <div className={style.block} style={{background:`${theme.background}`}}>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        100%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor01} value={100}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas semanales:&emsp;
                                    <span className={style.mid_bold_text}>{weekVisits}</span>
                                </p>
                            </div>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        {Math.floor(todayVisits * 100 / weekVisits) || 0}%
                                    </p>
                                    
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor04} value={Math.floor(todayVisits * 100 / weekVisits)}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas diarias:&emsp;
                                    <span className={style.mid_bold_text}>{todayVisits || null}</span>
                                </p>
                            </div>

                        </div>

                    </div>

                </>
            }

        </MainLayOut>
    )
};

export default Analitics;
