import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const getAllSectionsAPI = async (token) => {
    try {
        const allSections = await fetch(`${apipath}/admin/sections/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
        })
        return await allSections.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const createNewSectionAPI = async (data, token) => {
    try {
        const newSection = await fetch(`${apipath}/admin/sections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`,
            },
            body:  JSON.stringify(data)
        });
        return await newSection.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error en el servidor al intentar crear una nueva sección: ${error}`};
    }
};

const updateSectionAPI = async (data, id,  token) => {
    try {
        const newSection = await fetch(`${apipath}/admin/sections?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`,
            },
            body:  JSON.stringify(data)
        });
        return await newSection.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error en el servidor al intentar crear una nueva sección: ${error}`};
    }
};

const deleteSectionAPI = async (id,  token) => {
    try {
        const deleteSection = await fetch(`${apipath}/admin/sections?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`,
            }
        });
        return await deleteSection.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error en el servidor al intentar crear una nueva sección: ${error}`};
    }
};

const arrangeSectionsAPI = async (data, token) => {
    try {
        const arranged = await fetch(`${apipath}/admin/sections/arrange`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await arranged.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

export { getAllSectionsAPI, createNewSectionAPI, updateSectionAPI, deleteSectionAPI, arrangeSectionsAPI };
