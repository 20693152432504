import { ImageUploader } from '../../../../assets';

import style from './editor.module.scss';


const ImageUploadGallery = ({ id, uploadHandler, theme}) => {


    return (
        <div className={style.image_epigraph} style={{background: `${theme.background}`}}>

            <div className={style.title_content}>
                <p className={style.bold_text} style={{fontSize:'15px',color: `${theme.text}`}}>
                    Nueva imágen de galería
                </p>
                    <ImageUploader id={id} handler={uploadHandler} />
            </div>

        </div>
    )
};

export default ImageUploadGallery;
