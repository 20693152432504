const CategoryIcon = ({width='36px', height='36px', fill}) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 36 36" xmlSpace="preserve">
            <path fill={fill} d="M18,17.6c-0.2,0-0.5,0-0.7-0.1L1.1,10.7C0.4,10.4,0,9.7,0,8.9s0.4-1.4,1.1-1.7l16.1-6.8c0.5-0.2,1-0.2,1.4,0
                l16.1,6.8c0.7,0.3,1.1,1,1.1,1.7s-0.5,1.4-1.1,1.7l-16.1,6.8C18.5,17.6,18.2,17.6,18,17.6z M6.7,8.9L18,13.7l11.3-4.8L18,4.2
                L6.7,8.9z"/>
            <path fill={fill} d="M18,36c-0.2,0-0.5,0-0.7-0.1L1.1,29c-0.9-0.4-1.4-1.5-1-2.5c0.4-1,1.5-1.4,2.4-1L18,32.1l15.4-6.5
                c0.9-0.4,2,0.1,2.4,1c0.4,1-0.1,2.1-1,2.5l-16.1,6.8C18.5,35.9,18.2,36,18,36z"/>
            <path fill={fill} d="M18,29.9c-0.2,0-0.5,0-0.7-0.1L1.1,22.9c-0.9-0.4-1.4-1.5-1-2.5s1.5-1.4,2.4-1L18,26l15.4-6.5
                c0.9-0.4,2,0.1,2.4,1c0.4,1-0.1,2.1-1,2.5l-16.1,6.8C18.5,29.8,18.2,29.9,18,29.9z"/>
            <path fill={fill} d="M18,23.8c-0.2,0-0.5,0-0.7-0.1L1.1,16.9c-0.9-0.4-1.4-1.5-1-2.5c0.4-1,1.5-1.4,2.4-1L18,19.9l15.4-6.5
                c0.9-0.4,2,0.1,2.4,1c0.4,1-0.1,2.1-1,2.5l-16.1,6.8C18.5,23.8,18.2,23.8,18,23.8z"/>
        </svg>
    )
};

export default CategoryIcon;