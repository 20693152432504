import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AppContextProvider, UserContextProvider, ModalContextProvider, MenuContextProvider, ThemeContextProvider } from './contexts';

import App from './App';



ReactDOM.createRoot(document.getElementById('root')).render(
    // <React.StrictMode>
            <AppContextProvider>
                <UserContextProvider>
                    <MenuContextProvider>
                        <ModalContextProvider>
                            <ThemeContextProvider>
                                <BrowserRouter>
                                    <App />
                                </BrowserRouter>
                            </ThemeContextProvider>
                        </ModalContextProvider>
                    </MenuContextProvider>
                </UserContextProvider>
            </AppContextProvider>
    // </React.StrictMode>
);
