import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;



const getAllHilightsAPI = async (token) => {
    try {        
        const all = await fetch(`${apipath}/admin/hilights`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await all.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las noticias destacadas: ${error}`
        }
    }
};

const newHilightAPI = async (id, position, token) => {
    try {        
        const data = { id, position };
        const newHilight = await fetch(`${apipath}/admin/hilights`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await newHilight.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar crear una nueva entrada destacada: ${error}`
        }
    }
};

const deleteHilightAPI = async (id, token) => {
    try {        
        const data = { id };
        const deleted = await fetch(`${apipath}/admin/hilights`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await deleted.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar crear una nueva entrada destacada: ${error}`
        }
    }
};

const arrangeHilightsAPI = async (data, token) => {
    try {
        const arranged = await fetch(`${apipath}/admin/hilights`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await arranged.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar guardar el orden de los destacados ${error}`
        }
    }
};

export { newHilightAPI, getAllHilightsAPI, deleteHilightAPI, arrangeHilightsAPI };
