import { EditIcon, PlayIcon, PauseIcon, DeleteIcon } from '../../../assets';

import style from './row.module.scss';


const CategoryRow = ({i, category, editHandler, playPauseHandler, deleteHandler, theme, user, dragRow, draggedOverRow, handleSort }) => {
    return (
        <div
            className={style.main_row_content}
            style={{background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}
            draggable
            onDragStart={ () => dragRow.current = i}
            onDragEnter={() => draggedOverRow.current = i}
            onDragEnd={handleSort}
            onDragOver={(e) => {e.preventDefault()}}
        >
            <p className={`${style.category_id} ${style.small_bold_text}`} style={ category.active ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`}}>{category.active ? 'on': 'off'}</p>
        {/* {
            category.active ?
            <p onClick={() => playPauseHandler(category.id, false)} className={`${style.category_id} ${style.small_bold_text}`} style={ {backgroundColor: `${theme.on}`,cursor:'pointer'}}>on</p>
            :
            <p onClick={() => playPauseHandler(category.id, true)} className={`${style.category_id} ${style.small_bold_text}`} style={ {backgroundColor: `${theme.off}`,cursor:'pointer'}}>off</p>
        } */}
            <div className={style.category_row}>
                
                <div className={style.category_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.category_title}>
                            <p className={style.big_bold_text} style={{color:`${theme.text}`}}>{category.name}</p>
                        </div>
                    </div>
                    <div className={style.id_title}>
                        <div className={style.category_title}>
                            <p className={style.light_text} style={{color:`${theme.text}`}}>{category.description}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>
                        <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{category.createdAt.split('T')[0]}</p>
                    </div>

                </div>
                
                <div className={style.pub_icons}>
                
                    <div onClick={ () => editHandler(category.id, category.name, category.description) } style={{cursor: 'pointer'}}>
                        <EditIcon width='19px' height='19px' fill={theme.alttext} />
                    </div>
                    {
                        category.active ?
                        <div onClick={() => playPauseHandler(category.id, false)} style={{cursor: 'pointer'}}>
                            <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                        :
                        <div onClick={() => playPauseHandler(category.id, true)} style={{cursor: 'pointer'}}>
                            <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                    }
                    {
                        user.roleId === 1
                        &&
                        <div onClick={() => deleteHandler(category.id, category.name)} style={{cursor: 'pointer'}}>
                            <DeleteIcon width='17px' fill={theme.primary}/>
                        </div>
                    }
                </div>
              
            </div>
        </div>
    )
};

export default CategoryRow;
