import style from './editor.module.scss';


const Popup = ({ items, defaultName, nameId, storedValue, handler, theme }) => {
    return (
        <div className={style.blocks_container_popups} style={{background: `${theme.background}`}}>
            <p className={style.small_bold_text} style={{color:`${theme.text}`, width:'90px', textAlign:'right'}}>{defaultName}</p>
            <select className={`${style.selector} ${style.max_selector}`} name={nameId} onChange={handler}>
                <option defaultValue value={null}>sin {defaultName}</option>                                    
                { items.length &&
                    items.map( item => 
                        <option key={item.id} value={(item.id)} selected={storedValue === item.id ? true : false} >{item.name}</option>
                    )
                }                                    
            </select>
        </div>
    )
};

export default Popup;
