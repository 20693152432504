import config from '../../../config';
import { useState, useEffect, useContext, useRef } from 'react';

import { error_type } from '../../../models';
import { AppContext, UserContext, MenuContext, ModalContext, ThemeContext } from '../../../contexts';

import { localDate } from '../../../assets';

import { useNavigateTo } from '../../../hooks';
import { getPublicationByIdToEditAPI } from '../../../services';

import { privateRoutes } from '../../../routes';

import Block from './components/block';
import style from './preview.module.scss';



const PreviewPublication = () => {

    const { idEditPublication } = useContext(AppContext);
    const { activateNotification } = useContext(ModalContext);
    const { deleteUser, token, deleteToken } = useContext(UserContext);
    
    const [ isLoading, setIsLoading ] = useState(false);
    const [ allTags, setAllTags ] = useState([]);

    const [ publication, setPublication] = useState([]);
    const [ goto ] = useNavigateTo();


    useEffect( () => {
        setIsLoading(true);
        if (!idEditPublication) {
            setIsLoading(false);
            goto(privateRoutes.PUBLICATIONS);
            return;
        };
        getPublicationByIdToEditAPI(idEditPublication, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 5000);
                goto(privateRoutes.LISTPUBLICATIONS);
                return;
            };
            const tags = res?.data?.tag?.length ? res.data.tag.split('#') : [];
            setAllTags(tags);
            setPublication(res.data);
            setIsLoading(false);
        })
    }, []);

    const scrollViewed = useRef();
    const scrollLeftDiv  = (ref) => { ref.current.scrollLeft -= 540; };
    const scrollRightDiv = (ref) => { ref.current.scrollLeft += 540; };

    return (
        <div className={style.main_container}>
        {
            publication?.tag
            ?
            <div className={style.categories}>
                {
                    publication?.tag.split('#').map( (tag, i) => {
                        return (
                            <div key={i} style={{display:'flex', flexDirection:'row',gap:'10px'}}>
                                <p className={style.category}>{tag}</p>
                                { (publication.tag.split('#').length > 0) && i < (publication.tag.split('#').length - 1 ) ? <p className={style.split}> | </p>:null }
                            </div>
                        )
                    })
                }
            </div>
            :
            <div className={style.categories}>
                { 
                    publication?.component ? 
                    <>
                        <p className={style.category}>
                            {publication?.component?.name}
                        </p>
                    </>
                    :
                    null
                }
                { 
                    ( publication?.component && publication?.section) || (publication?.component && publication?.category) || (publication?.component && publication?.tag)
                    ?
                    <p className={style.category}> | </p>
                    :
                    null
                }
                {
                    publication.section
                    ?
                    <>
                        <p className={style.category}>
                            {publication?.section?.name}
                        </p>
                    </>
                    :
                    null
                }
                { 
                    publication?.section && publication?.category
                    &&
                    <p className={style.category}> | </p>
                }
                { 
                    publication.category ?
                    <>
                        <p className={style.category}>
                            {publication?.category?.name}
                        </p>
                    </>
                    :
                    null
                }
             
            </div>
        }
        

        <div className={style.main_title_container}>
            <h1 className={style.main_title}>{publication.title}</h1>
            {
                publication.showDate
                ?
                <p className={style.date}>{localDate(publication.availability)}</p>
                :
                null
            }
        </div>

        <p className={style.summary}>{publication.summary}</p>
        
        <div className={style.pub_content}>
            { publication.coverImage && <img className={style.image} src={`${config.api_statics}/${publication.folder}/${publication.coverImage}`} alt={publication.summary} /> }

            <Block order={1}  paragraph={publication.paragraph01} image={publication.image01} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph01} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={2}  paragraph={publication.paragraph02} image={publication.image02} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph02} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={3}  paragraph={publication.paragraph03} image={publication.image03} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph03} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={4}  paragraph={publication.paragraph04} image={publication.image04} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph04} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={5}  paragraph={publication.paragraph05} image={publication.image05} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph05} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={6}  paragraph={publication.paragraph06} image={publication.image06} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph06} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={7}  paragraph={publication.paragraph07} image={publication.image07} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph07} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={8}  paragraph={publication.paragraph08} image={publication.image08} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph08} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={9}  paragraph={publication.paragraph09} image={publication.image09} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph09} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={10} paragraph={publication.paragraph10} image={publication.image10} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph10} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            
            <Block order={11} paragraph={publication.paragraph11} image={publication.image11} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph11} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={12} paragraph={publication.paragraph12} image={publication.image12} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph12} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={13} paragraph={publication.paragraph13} image={publication.image13} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph13} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={14} paragraph={publication.paragraph14} image={publication.image14} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph14} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={15} paragraph={publication.paragraph15} image={publication.image15} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph15} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={16} paragraph={publication.paragraph16} image={publication.image16} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph16} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={17} paragraph={publication.paragraph17} image={publication.image17} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph17} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={18} paragraph={publication.paragraph18} image={publication.image18} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph18} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={19} paragraph={publication.paragraph19} image={publication.image19} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph19} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
            <Block order={20} paragraph={publication.paragraph20} image={publication.image20} path={config.api_statics} folder={publication.folder} epigraph={publication.epigraph20} audiogallery={publication.audiogalleries} videogallery={publication.videogalleries}/>
       

            {
                publication?.imagegalleries?.length ?
                <div className={style.gallery_content}>
                <p className={style.small_title}><span>+ </span>imágenes</p>    
                    <button className={style.scroll_button_left}  onClick={() => scrollLeftDiv(scrollViewed)}></button>
                    <button className={style.scroll_button_right} onClick={() => scrollRightDiv(scrollViewed)}></button>
                    <div className={style.image_gallery} ref={scrollViewed}>

                        {
                            publication?.imagegalleries?.map( image => {
                            return (
                                <div key={image.id}>
                                    <a href={`${config.api_static}/${image.image}`} target='blank'>
                                        <img className={style.individual_image} src={`${config.api_statics}/${image.image}`}/>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div>
                :
                <></>
            }

        </div>
    </div>
    )

};

export default PreviewPublication;
