import { ImageUploader, ImageDeleter } from '../../../../assets';

import style from './editor.module.scss';


const ImageCover = ({ id, publication, imageCompletePath, uploadHandler, deleteHandler, theme}) => {


    return (
        <div className={style.image_epigraph} style={{background: `${theme.background}`}}>

            <div className={style.title_content}>
                <p className={style.bold_text} style={{fontSize:'15px',color: `${theme.text}`}}>
                    Imagen de portada
                </p>
                { 
                    publication[id] === null
                    ?
                    <ImageUploader id={id} handler={uploadHandler} />
                    :
                    <ImageDeleter id={id} imgCompletePath={imageCompletePath} handleDelete={deleteHandler} />
                }
            </div>

        </div>
    )
};

export default ImageCover;
