import { useState } from 'react';

import ToggleSwitch from '../../toggleswitch';

import { EditIcon, DeleteIcon } from '../../../../assets';

import style from '../lists.module.scss';


const Listcard = ( { i, id, image, title, link, active, theme, dragCard, draggedOverCard, deleteHandler, sortHandler, activateHandler, editHandler } ) => {

    const [ isEditing, setIsEditing ] = useState(false);
    const [ imageContent, setImageContent ] = useState(image);
    const [ titleContent, setTitleContent ] = useState(title);
    const [ linkContent, setLinkContent ] = useState(link);

    const cancelEditing = () => {
        setIsEditing(false);
        setImageContent(image);
        setTitleContent(title);
        setLinkContent(link);
    };

    const editLink = () => {
        const data = {
            image: imageContent,
            title: titleContent,
            link: linkContent
        };
        editHandler(id, data)
        setIsEditing(false);
    };

    return (
        <div
            key={id}
            className={style.list_card}
            style={{background:`${theme.background}`}}
            draggable
            onDragStart={ () => dragCard.current = i}
            onDragEnter={ () => draggedOverCard.current = i}
            onDragEnd={sortHandler}
            onDragOver={(e) => {e.preventDefault()}}
        >
            <a href={link} target="_blank">
                <img className={style.list_image} src={image}/>
            </a>

            <p style={{color:`${theme.text}`}}>{title.substring(0,56)} {title.length > 56 ? '...' : '.'}</p>

            <div className={style.edit_icons}>
                <ToggleSwitch id={id} handleChange={activateHandler} status={active}/>

                <div className={style.edit_icon} onClick={()=> setIsEditing(true)}>
                    <EditIcon height='20px' fill={`${theme.text}`} />
                    <p style={{color:`${theme.text}`}}>editar</p>
                </div>
    
                <div className={style.edit_icon} style={{background:`${theme.background}`,gap:'6px'}} onClick={()=> deleteHandler(id, title)}>
                    <DeleteIcon width='18px' fill={`${theme.text}`}/>
                    <p style={{color:`${theme.text}`}}>borrar</p>
                </div>
            </div>
            
            {
                isEditing
                ?
                <div className={style.edit_form} style={{color:`${theme.text}`,gap:'18px'}}>

                    <div className={style.edit_block}>
                            <p className={style.text}>Imagen</p>
                            <input className={style.input} value={imageContent || ''} onChange={(e)=>setImageContent(e.target.value)}/>
                    </div>

                    <div className={style.edit_block}>
                        <p className={style.text}>Título</p>
                        <input className={style.input} value={titleContent || ''} onChange={(e)=>setTitleContent(e.target.value)}/>
                    </div>
                    
                    <div className={style.edit_block}>
                        <p className={style.text}>Link</p>
                        <input className={style.input} value={linkContent || ''}  onChange={(e)=>setLinkContent(e.target.value)}/>
                    </div>

                    <div style={{display:'flex',flexDirection:'row',gap:'12px'}}>
                        <button className={style.cancel} onClick={cancelEditing}>cancelar</button>
                        <button className={style.submit} onClick={editLink}>aceptar</button>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    );
}

export default Listcard;
