import { Route, Navigate } from 'react-router-dom';
import { publicRoutes, privateRoutes } from './routes';

import { AuthGuard, EditGuard, NotEditingGuardGuard,  RouteNotFound } from './guards';

import { LogIn, Dashboard, Publications, EditPublication, ListPublications, Hilights, Components, Sections, Categories, Tags, Sidebar, Banners, Players, Lists, Analitics, PreviewPublication, Settings } from './sections';
import { ModalMessage } from './components';

import './styles/style.css'

export default function App() {
    return (
        <>
            <ModalMessage />
            <RouteNotFound path={publicRoutes.HOME}>
                
                <Route path={publicRoutes.LOGIN} element={<LogIn />} />

                <Route element={<AuthGuard />}>
                    <Route element={<NotEditingGuardGuard />}>
                        <Route path={publicRoutes.HOME} element={ <Navigate to={privateRoutes.DASHBOARD}/>}/>
                        <Route path={privateRoutes.DASHBOARD} element={<Dashboard />} />
                        
                        <Route path={privateRoutes.PUBLICATIONS} element={<Publications />} />
                            
                        <Route path={privateRoutes.LISTPUBLICATIONS} element={<ListPublications />} />
                        <Route path={privateRoutes.HILIGHTS} element={<Hilights />} />
                        
                        <Route path={privateRoutes.COMPONENTS} element={<Components />} />
                        <Route path={privateRoutes.SECTIONS} element={<Sections />} />
                        <Route path={privateRoutes.CATEGORIES} element={<Categories />} />
                        <Route path={privateRoutes.TAGS} element={<Tags />} />
                        <Route path={privateRoutes.SIDEBAR} element={<Sidebar />} />
                        <Route path={privateRoutes.BANNERS} element={<Banners />} />
                        <Route path={privateRoutes.PLAYERS} element={<Players />} />
                        <Route path={privateRoutes.LISTS} element={<Lists />} />

                        <Route path={privateRoutes.ANALITICS} element={<Analitics />} />
                        <Route path={privateRoutes.SETTINGS} element={<Settings />} />
                    </Route>
                    
                    <Route element={<EditGuard />}>
                        <Route path={privateRoutes.EDITPUBLICATION} element={<EditPublication />} />
                        <Route path={privateRoutes.PREVIEWPUBLICATION} element={<PreviewPublication />} />
                    </Route>

                </Route>
            
            </RouteNotFound>
        </>
    )
};
