import { EditIcon, PlayIcon, PauseIcon, DeleteIcon } from '../../../assets';

import style from './row.module.scss';


const ComponentRow = ({i, component, editHandler, playPauseHandler, deleteHandler, theme, user, dragRow, draggedOverRow, handleSort }) => {
    return (
        <div
            className={style.main_row_content}
            style={{background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}
            draggable
            onDragStart={ () => dragRow.current = i}
            onDragEnter={ () => draggedOverRow.current = i}
            onDragEnd={handleSort}
            onDragOver={(e) => {e.preventDefault()} }
        >
            {/* <p className={`${style.category_id} ${style.small_bold_text}`} style={ component.active ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`}}>{component.position + 1}</p> */}
            <p className={`${style.category_id} ${style.small_bold_text}`} style={ component.active ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`}}>{component.active ? 'on': 'off'}</p>
            <div className={style.category_row}>
                
                <div className={style.category_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.category_title}>
                            <p className={style.big_bold_text} style={{color:`${theme.text}`}}>{component.name}</p>
                        </div>
                    </div>
                    <div className={style.id_title}>
                        <div className={style.category_title}>
                            <p className={style.light_text} style={{color:`${theme.text}`}}>{component.description}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>
                        <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{component.createdAt.split('T')[0]}</p>
                    </div>

                </div>
                
                <div className={style.pub_icons}>
                
                    <div onClick={ () => editHandler(component.id, component.name, component.description) } style={{cursor: 'pointer'}}>
                        <EditIcon width='19px' height='19px' fill={theme.alttext} />
                    </div>
                    {
                        component.active ?
                        <div onClick={() => playPauseHandler(component.id, false)} style={{cursor: 'pointer'}}>
                            <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                        :
                        <div onClick={() => playPauseHandler(component.id, true)} style={{cursor: 'pointer'}}>
                            <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                    }
                    {
                        user.roleId === 1
                        &&
                        <div onClick={() => deleteHandler(component.id, component.name)} style={{cursor: 'pointer'}}>
                            <DeleteIcon width='17px' fill={theme.primary}/>
                        </div>
                    }
                </div>
              
            </div>
        </div>
    )
};

export default ComponentRow;
