const AnaliticsIcon = ({width='36px', height='36px', fill}) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 36 36" xmlSpace="preserve" fill={fill}>
            <path d="M9.5,5.7h-8C0.7,5.7,0,6.4,0,7.2v27.3C0,35.3,0.7,36,1.5,36h8c0.8,0,1.5-0.7,1.5-1.5V7.2C11,6.4,10.3,5.7,9.5,5.7z"/>
            <path d="M22,0h-8c-0.8,0-1.5,0.7-1.5,1.5v33c0,0.8,0.7,1.5,1.5,1.5h8c0.8,0,1.5-0.7,1.5-1.5v-33C23.5,0.7,22.8,0,22,0z"/>
            <path d="M34.5,11.5h-8c-0.8,0-1.5,0.7-1.5,1.5v21.5c0,0.8,0.7,1.5,1.5,1.5h8c0.8,0,1.5-0.7,1.5-1.5V13C36,12.1,35.3,11.5,34.5,11.5z"/>
        </svg>
    );
}

export default AnaliticsIcon;
