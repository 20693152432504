import config from '../../../../../config';
import { AddIcon, DeleteIcon } from '../../../../../assets';

import style from './card.module.scss';


const HilightCard = ({i, id, publication, dragCard, draggedOverCard, handleSort, deleteHandler, theme}) => {
    return (
        <div
            className={style.block} style={{cursor: 'default', background:`${theme.background}`}}
            draggable
            onDragStart={ () => dragCard.current = i}
            onDragEnter={ () => draggedOverCard.current = i}
            onDragEnd={handleSort}
            onDragOver={(e) => {e.preventDefault()} }
        >
            <div className={style.publication_image} style={{backgroundImage: `url(${config.api_statics}/${publication.folder}/${publication.coverImage})`}}></div>
            
            <p className={`${style.big_bold_text} ${style.card_title}`} style={{color: `${theme.text}`}}>{publication.title}</p>
            
            <div className={style.delete_icon} onClick={() => deleteHandler(id)} style={{cursor:'pointer',background:`${theme.background}`}}>
                <DeleteIcon fill={theme.strokeColor01}/>
            </div>

        </div>         
    )
};

export default HilightCard;
