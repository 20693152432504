import { useState, useEffect, useContext } from 'react';

import { UserContext, MenuContext, ModalContext, ThemeContext } from '../../contexts';

import { MainLayOut } from '../../layouts';
import TagRow from './components/tagsrow';
import { AddIcon } from '../../assets';

import { createNewTagAPI, getAllTagsAPI, updateTagAPI, deleteTagAPI } from '../../services';

import { error_type } from '../../models';

import style from './tags.module.scss';



const Tags = () => {

    const { user, token, deleteToken, deleteUser } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);
    const { tags, saveTags } = useContext(MenuContext);


    const [ createModal, setCreateModal ] = useState(false);
    const [ editModal, setEditModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    

    const [ nameEdit, setNameEdit ] = useState('');
    const [ descriptionEdit, setDescriptionEdit ] = useState('');
    const [ idToEdit, setIdToEdit ] = useState(null);
    const [ idToDelete, setIdToDelete ] = useState(null);


    useEffect(() => {
        getAllTagsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            saveTags(res.data);
            return;
        })
    },[]);


    const openEditModal = (id, name, description) => {
        setEditModal(true);
        setNameEdit(name);
        setDescriptionEdit(description);
        setIdToEdit(id);
    };

    const closeEditModal = () => {
        setEditModal(false);
        setNameEdit('');
        setDescriptionEdit('');
        setIdToEdit(null);
    };

    const openCreateModal = (id) => {
        setCreateModal(true);
    };
    
    const closeCreateModal = () => {
        setCreateModal(false);
    };

    const handleNewTag = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const description = e.target.description.value;
        if (!name || !description) {
            activateNotification('Por favor, ingresá un título y una descripción para la sección', 'error', true, 4000);
            return;
        }
        const data = {
            name,
            description
        };
        createNewTagAPI(data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const newData = [...tags, res.data];
            saveTags(newData);
            e.target.name.value = '';
            e.target.description.value = '';
            closeCreateModal();
            return;
        })

    };

    const handleEditTag = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const description = e.target.description.value;
        const data = {
            name,
            description,
            id: idToEdit
        };
        updateTagAPI(data, idToEdit, token)
        .then(res => {
            // console.log(res)
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const filtered = tags.filter(tag => tag.id !== idToEdit);
            const updatedContent = [...filtered, res.data].sort( (a,b) => a.id - b.id);
            saveTags(updatedContent);
            e.target.name.value = '';
            e.target.description.value = '';
            closeEditModal();
            return;
        })
    };

    const playPauseTag = (id, param) => {
        const data = {
            active: param
        };
        updateTagAPI(data, id, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            saveTags(res.data);
            closeEditModal();
            return;
        })
    };

    const openDeleteModal = (id) => {
        setIdToDelete(id);
        setDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setIdToDelete(null);
        setDeleteModal(false);
    };

    const handleDeleteTag = () => {
        deleteTagAPI(idToDelete, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    setIdToDelete(null);
                    setDeleteModal(false);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const filtered = tags.filter( item => item.id !== idToDelete);
            saveTags(filtered);
            setIdToDelete(null);
            setDeleteModal(false);
            return;
        })
    };
    

    return (
        <MainLayOut>
            <div className={style.title_container}>
                <p className={style.main_title} style={{color:`${theme.text}`}}>Etiquetas</p>
            </div>

            <div onClick={openCreateModal} className={style.block} style={{background: `${theme.background}`}}>
                    <AddIcon width='22px' height='22px' fill={theme.strokeColor02} />
                    <p className={style.light_text} style={{color: `${theme.text}`}}>Crear nueva etiqueta</p>
                </div>

            <div className={style.tags_list}>
                {
                    tags?.map( (tag, i) => {
                        return (
                            <TagRow key={tag.id} i={i} tag={tag} editHandler={openEditModal} playPauseHandler={playPauseTag} deleteHandler={openDeleteModal} theme={theme} user={user} />
                        )
                    })
                }
            </div>
            <div className={createModal ? `${style.modal_edition} ${style.blo} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                
                <div className={createModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                    
                    <form className={style.form} onSubmit={handleNewTag}>
                        <div className={style.block}>
                            <p className={style.bold_text}>Título</p>
                            <textarea className={`${style.textarea} ${style.big_input}`} name="name" />
                        </div>

                        <div className={style.block}>
                            <p className={style.bold_text}>Descripción</p>
                            <textarea className={style.textarea} name="description" />
                        </div>
                        <button type="submit" className={style.submit}>aceptar</button>
                    </form>

                    <div className={style.close_button} onClick={closeCreateModal}></div>

                </div>
            </div>

            <div className={editModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                
                <div className={editModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                    
                    <form onSubmit={handleEditTag} className={style.form}>
                        <div className={style.block}>
                            <p className={style.bold_text}>Título</p>
                            <textarea className={`${style.textarea} ${style.big_input}`} onChange={(e) => setNameEdit(e.target.value)} name="name" value={nameEdit || ""}/>
                        </div>

                        <div className={style.block}>
                            <p className={style.bold_text}>Descripción</p>
                            <textarea className={style.textarea} onChange={(e) => setDescriptionEdit(e.target.value)} name="description" value={descriptionEdit || ''} />
                        </div>
                        <button type="submit" className={style.submit}>guardar cambios</button>
                    </form>

                    <div className={style.close_button} onClick={closeEditModal}></div>

                </div>
            </div>

            <div className={deleteModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                
                <div className={deleteModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>
            
                    <p className={style.text} style={{color:'#EEE'}}>Borrar la sección con id {idToDelete}?</p>                    
                    <div className={style.modal_openclose_buttons}>
                        {/* <button className={style.cancel}>canclar</button> */}
                        <button onClick={handleDeleteTag}className={style.submit}>ACEPTAR</button>
                        <div className={style.close_button} onClick={closeDeleteModal}></div>
                    </div>

                </div>
            </div>
        </MainLayOut>
    )
};

export default Tags;
