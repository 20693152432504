import { useState, useEffect, useRef, useContext } from 'react';

import { MainLayOut } from '../../../layouts';
import { DeleteIcon, Loader, SearchIcon } from '../../../assets';

import { AppContext, UserContext, ModalContext, ThemeContext } from '../../../contexts';
import { useNavigateTo } from '../../../hooks';

import { allPublicationsAPI, getAllPublicationsByParamAPI, newHilightAPI, getAllHilightsAPI, deleteHilightAPI, arrangeHilightsAPI } from '../../../services/';
import { privateRoutes } from '../../../routes';
import { error_type } from '../../../models';

import PublicationRow from './components/pubrow';
import HilightCard from './components/hilightcard';

import style from './list.module.scss';



const Hilights = () => {

    const [ goto ] = useNavigateTo();

    const dragCard = useRef(0);
    const draggedOverCard = useRef(0);
    const arrangeCardsTimer = useRef(null);

    const {
            // allHilightPublications, setAllHilightPublications,
            pubsPerPage, setPubsPerPage, currentPage, setCurrentPage,
            totalPublications, setTotalPublications, totalPages, setTotalPages
        } = useContext(AppContext);

    const { token, deleteUser, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ allHilightPublications, setAllHilightPublications ] = useState([]);
    const [ hilights, setHilights ] = useState([]);
    const [ idHilights, setIdHilights ] = useState([]);

    const [ inputSearch, setInputSearch ] = useState('');
    const [ param, setParam ] = useState('all');
    const valueRef = useRef(null);
    
    const setPageValue = (e) => {
        const value = Number(e.target.value);
        if (!isNaN(value) && value > 0 && value <= totalPages ) {
            setCurrentPage(value);
            searchPubsByParam(value);
        };
        return;
    };
    
    const handleChangePubsPerPage = (e) => {
        setPubsPerPage(e.target.value);
    };

    const searchPubsByParam = (page) => {
        setIsLoading(true);
        const value = valueRef.current.value;
        if (param === '') { 
            activateNotification(`Seleccioná un criterio de búsqueda.`, 'error', true, 3000);
            setIsLoading(false);
            return;
        }
        if (param === 'all') {    
            setInputSearch('');
            allPublicationsAPI(page, pubsPerPage, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalPublications('');
                        setTotalPages('');
                        setAllHilightPublications([]);
                        setCurrentPage('');
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    setIsLoading(false);
                    // goto(privateRoutes.PUBLICATIONS);
                    return;
                };
                setAllHilightPublications(res.data.rows);
                setTotalPublications(res.data.count);
                setTotalPages(Math.ceil(res.data.count / pubsPerPage));
                setPubsPerPage(Number(res.pubsperpage));
                setCurrentPage(Number(res.currentPage));
                setIsLoading(false);
                return;
            });
            return;
        };
        if (value === '' || value === null) { 
            activateNotification(`Por favor, escribí lo que quieras buscar`, 'error', true, 3000);
            setIsLoading(false);
            return;
        }
        if (param === 'id' || param === 'title') {    
            getAllPublicationsByParamAPI(param, value, page, pubsPerPage, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalPublications('');
                        setTotalPages('');
                        setAllHilightPublications([]);
                        setCurrentPage('');
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    setIsLoading(false);
                    // goto(privateRoutes.PUBLICATIONS);
                    return;
                };
                setAllHilightPublications(res.data.rows);
                setTotalPublications(res.data.count);
                setTotalPages(Math.ceil(res.data.count / pubsPerPage));
                setPubsPerPage(Number(res.pubsperpage));
                setCurrentPage(Number(res.currentPage));
                setIsLoading(false);
                return;
            })
        };
    };

    const prevPage = () => {
        if (currentPage === 1) return;
        const page = currentPage - 1;
        searchPubsByParam(page);
    };

    const nextPage = () => {
        if (currentPage === totalPages) return;
        const page = currentPage + 1;
        searchPubsByParam(page);
    };

    const handleAddToHilights = (id) => {
        setIsLoading(true);
        newHilightAPI(id, 0, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    setTotalPublications('');
                    setTotalPages('');
                    setAllHilightPublications([]);
                    setCurrentPage('');
                    setIsLoading(false);
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3000);
                setIsLoading(false);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3000);
            setHilights(res.data);
            setIsLoading(false);
            return;
        })
    };

    const handleDeleteHilight = (id) => {
        deleteHilightAPI(id, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    setTotalPublications('');
                    setTotalPages('');
                    setAllHilightPublications([]);
                    setCurrentPage('');
                    setIsLoading(false);
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3000);
                setIsLoading(false);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3000);
            setHilights(res.data);
            setIsLoading(false);
            return;
        })
    };

    const updateHilightsOrder = (data) => {
        clearTimeout(arrangeCardsTimer.current);

        arrangeCardsTimer.current = setTimeout( () => {
            arrangeHilightsAPI(data, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalPublications('');
                        setTotalPages('');
                        setAllHilightPublications([]);
                        setCurrentPage('');
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    setIsLoading(false);
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 3000);
                setHilights(res.data);
                setIsLoading(false);
                return;
            })
        }, 1700)
    };

    const handleSortCards = () => {
        const cardsClone = [...hilights];
        const temp = cardsClone[dragCard.current];
        cardsClone[dragCard.current] = cardsClone[draggedOverCard.current];
        cardsClone[draggedOverCard.current] = temp;
        const sorted = cardsClone.map( (card, index) => {
            return { id: card.id, position: index}
        })
        updateHilightsOrder(sorted);
        setHilights(cardsClone);
    };

    useEffect( () => {
        setIsLoading(true);
        getAllHilightsAPI(token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    setHilights([]);
                    setIsLoading(false);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                setIsLoading(false);
                return;
            };
            setHilights(res.data);
            setIsLoading(false);
            return;
        })
    },[]);

    useEffect( () => {
        setIdHilights(hilights.map(hi => hi.publicationId));
    },[hilights])



    return (
        <MainLayOut>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <>
                    <div className={style.title_container}>
                        <p className={style.main_title} style={{cursor: 'pointer', color:`${theme.text}`}} onClick={()=>goto(privateRoutes.PUBLICATIONS)}>
                            Publicaciones destacadas
                            <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Lista de publicaciones</span>
                        </p>
                    </div>
                    
                    <div className={style.total_and_pagination} style={{background: `${theme.background}`}}>
                        
                        <div className={style.search_elements} style={{color:`${theme.text}`}}>
                            <select className={`${style.selector} ${style.big_selector}`} onChange={(e)=>setParam(e.target.value)}>
                                <option selected={false} value='all'>buscar por</option>
                                <option selected={param === 'all' ? true : false} value='all'>todas</option>
                                <option selected={param === 'id' ? true : false} value='id'>por id</option>
                                <option selected={param === 'title' ? true : false} value='title'>por título</option>
                            </select>
                            <input
                                className={`${style.input} ${style.mid_input}`}
                                placeholder='ingresar texto'
                                ref={valueRef}
                                value={inputSearch || ''}
                                onChange={ (e) => setInputSearch(e.target.value) }
                                onKeyDown={ (e) => { if (e.key === 'Enter') { searchPubsByParam(1) } }}
                            />
                            <button className={style.submit} onClick={() => searchPubsByParam(1)}>
                                <SearchIcon width='14px' height='14px' fill='white'/>
                            </button>
                            {
                                allHilightPublications.length ?
                                <div onClick={() => setAllHilightPublications([])} style={{padding: '0 6px'}}>
                                    <DeleteIcon width='18px' height='18px' fill={theme.strokeColor01}/>
                                </div>
                                :
                                <></>
                            }
                        </div>

                        <p className={style.small_light_text} style={{color: `${theme.text}`}}>Total: {totalPublications} {totalPublications > 1 ? 'publicaciones' : 'publicación'}</p>
                    </div>

                    <div className={style.results}>

                        <div className={style.pagination_per_page} style={{color: `${theme.text}`}}>
                            <p className={style.small_light_text}>resultados por página</p>
                            <select className={style.selector} onChange={handleChangePubsPerPage} >
                                <option selected={ pubsPerPage ==  5 ? true : false } value="5">5</option>
                                <option selected={ pubsPerPage == 10 ? true : false } value="10">10</option>
                                <option selected={ pubsPerPage == 20 ? true : false } value="20">20</option>
                                <option selected={ pubsPerPage == 30 ? true : false } value="30">30</option>
                            </select>
                        </div>

                        <div className={style.pagination_pages}>
                            <p className={style.small_light_text} style={{color: `${theme.text}`}}>página</p>
                            <input className={`${style.input} ${style.small_input}`} type="text" value={currentPage} placeholder={currentPage.toString()} onChange={setPageValue} />
                            <p className={style.small_light_text} style={{color: `${theme.text}`}}>de {totalPages}</p>
                            <div className={style.pagination_buttons}>
                                <button className={`${style.submit} ${style.prev_next} ${style.prev}`} onClick={prevPage}></button>
                                <button className={`${style.submit} ${style.prev_next} ${style.next}`} onClick={nextPage}></button>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className={style.pubs_list}>
                        {
                            allHilightPublications.map( (publication, i) => {
                                return (
                                    <PublicationRow key={publication.id} i={i} publication={publication} idHilights={idHilights} addHandler={handleAddToHilights} theme={theme} />
                                )
                            })
                        }
                    </div>

                    <div className={style.hilights_list}>
                        {
                            hilights.map( (pub, i) => {
                                return (
                                    <HilightCard key={pub.id} i={i} id={pub.id} publication={pub.publication} dragCard={dragCard} draggedOverCard={draggedOverCard} handleSort={handleSortCards} deleteHandler={handleDeleteHilight} theme={theme}/>
                                )
                            })
                        }
                    </div>
                </>
            }

        </MainLayOut>
    )
};

export default Hilights;