import { useState, useEffect, useRef, useContext } from 'react';

import { AddIcon, EditIcon, Loader } from '../../../assets';
import { AppContext, ModalContext, ThemeContext, UserContext } from '../../../contexts';

import { MainLayOut } from '../../../layouts';
import { privateRoutes } from '../../../routes';
import { useNavigateTo } from '../../../hooks';

import { updateSidebarContentAPI, getAllPlayersAPI, createNewPLayerAPI, updatePlayerByIdAPI, updatePlayersOrderAPI, deletePlayerByIdAPI } from '../../../services';

import ToggleSwitch from '../toggleswitch';
import Playercard from './components/playercard';

import { error_type } from '../../../models';

import style from './players.module.scss';



const Players = () => {

    
    const { user, deleteUser, token, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { arranges, setArranges } = useContext(AppContext);
    const { theme } = useContext(ThemeContext);
    const [ goTo ] = useNavigateTo();
    
    const [ isLoading, setIsLoading ] = useState(false);
    const [ players, setPlayers ] = useState([]);
    const [ newPlayerModal, setNewPlayerModal ] = useState(false);

    const defaultArrangeState = () => { return arranges.filter(arrange => arrange.name === 'players')[0] || {}; };
    const [ playersArrange, setPlayersArrange ] = useState(defaultArrangeState);

    const [ titleEdition, setTitleEdition ] = useState(false);

    const [ infoModal, setInfoModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ idToDelete, setIdToDelete ] = useState(null);
    const [ titleToDelete, setTitleToDelete ] = useState(null);

    const dragCard = useRef(0);
    const draggedOverCard = useRef(0);
    const arrangeCardsTimer = useRef(null);

    const handleEditTitle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPlayersArrange( prev => {
            const newData = {...prev};
            newData[name] = value;
            return newData;
        })
    };
    const cancelTitleEdition = () => {
        setTitleEdition(false);
        setPlayersArrange(defaultArrangeState); 
    };
    const handleChangeArrangeTitleStatus = (id, status) => {
        const data = {
            id,
            showTitle: status
        };
        updateSidebarContentAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setPlayersArrange( prev => {
                const newData = {...prev};
                newData.showTitle = status;
                return newData;
            });
            return;
        });
    };
    const handleChangeTitle = () => {
        const id = playersArrange.id;
        const data = {
            title: playersArrange.title
        };
        updateSidebarContentAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setTitleEdition(false);
            return;
        });
    };

    const handleCreateNewPlayer = (e) => {
        e.preventDefault();

        const description = e.target.description.value;
        const content = e.target.content.value;

        if (!content) return;
        
        const data = {description, content};
        createNewPLayerAPI(data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                setPlayers(res.data);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setPlayers(res.data);
            setNewPlayerModal(false);
            return;
        });
    };
    const updatePlayersOrder = (data) => {
        clearTimeout(arrangeCardsTimer.current)

        arrangeCardsTimer.current = setTimeout( () => {
            updatePlayersOrderAPI(data, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser();
                        deleteToken();
                        setIdToDelete(null);
                        activateNotification(`${res.message}`, 'error', true, 3800);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    setPlayers(res.data)
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 3800);
                return;
            })
        }, 1300);
    };
    const handleEditPlayer = (data, id) => {
        updatePlayerByIdAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setPlayers(res.data);
            return;
            
        })
    }
    const handleDeletePlayer = () => {
        deletePlayerByIdAPI(idToDelete, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    setDeleteModal(false);
                    activateNotification(`${res.message}`, 'error', true, 3400);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3400);
                setDeleteModal(false);
                setIdToDelete(null);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3400);
            setDeleteModal(false);
            setPlayers(res.data);
            setIdToDelete(null);
            return;
        })
    };
    const openDeleteModal = (id, description) => {
        setIdToDelete(id);
        setTitleToDelete(description);
        setDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setIdToDelete(null);
        setTitleToDelete(null);
        setDeleteModal(false);
    };
    const activatePlayer = (id, status) => {
        const data = {
            active: status
        }
        updatePlayerByIdAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setPlayers(res.data);
            return;
        })
    };
    const handleSortCards = () => {
        const cardsClone = [...players];
        const temp = cardsClone[dragCard.current];
        cardsClone[dragCard.current] = cardsClone[draggedOverCard.current];
        cardsClone[draggedOverCard.current] = temp;
        const sorted = cardsClone.map( (card, index) => {
            return { id: card.id, position: index };
        });
        updatePlayersOrder(sorted);
        setPlayers(cardsClone);
    };


    useEffect( () => {
        setIsLoading(true);
        getAllPlayersAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            setPlayers(res.data);
            setIsLoading(false);
        })
    },[]);


    return (
        <MainLayOut>
        {
            isLoading
            ?
            <Loader background={theme.alphaBackground}/>
            :
            <>
                <div className={style.title_container}>
                    <p className={style.main_title} style={{cursor: 'pointer', color:`${theme.text}`}} onClick={()=> goTo(privateRoutes.SIDEBAR)}>
                        Barra lateral
                        <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Reproductores</span>
                    </p>
                    <p className={style.submit} onClick={()=>setInfoModal(true)}>info</p>
                </div>

                <div className={style.block} style={{background: `${theme.background}`}} onClick={()=>setNewPlayerModal(true)}>
                    <AddIcon width='22px' height='22px' fill={theme.strokeColor02} />
                    <p className={style.light_text} style={{color: `${theme.text}`}}>Crear reproductor</p>
                </div>

                <div className={style.block_subtitle} style={{color:`${theme.text}`}}>Título general del bloque</div>
                <div className={style.title_block} style={{justifyContent:'flex-start',background: `${theme.background}`}}>
                    {
                        titleEdition
                        ?
                        <>
                            <input className={style.input} onChange={handleEditTitle} name="title" value={playersArrange.title || ''}/>
                            <div className={style.edit_buttons}>
                                <button className={style.cancel} onClick={cancelTitleEdition}>cancelar</button>
                                <button className={style.submit} onClick={handleChangeTitle}>aceptar</button>
                            </div>
                        </>
                        :
                        <div style={{display:'flex',flexDirection:'row',gap:'14px'}}>
                            <ToggleSwitch id={playersArrange.id} handleChange={handleChangeArrangeTitleStatus} status={playersArrange.showTitle}/>
                            <div className={style.title_and_icon} onClick={()=>setTitleEdition(true)}>
                                <p onClick={()=>setTitleEdition(true)} className={style.light_text} style={{color: `${theme.text}`}} dangerouslySetInnerHTML={{__html: playersArrange?.title || 'Sin Título. Click para editar'} }/>
                                <EditIcon width='22px' height='22px' fill={theme.strokeColor01}/>
                            </div>
                        </div>
                    }
                </div>

                <div className={style.block_subtitle} style={{color:`${theme.text}`}}>Reproductores</div>
                <div className={style.players_list}>
                    {
                        players?.length
                        ?
                        players.map( ({ id, content, description, active }, i) => {
                            return (
                                <Playercard
                                    key={id} i={i} id={id}
                                    content={content}
                                    description={description}
                                    active={active} 
                                    activateHandler={activatePlayer}
                                    dragCard={dragCard}
                                    draggedOverCard={draggedOverCard}
                                    sortHandler={handleSortCards}
                                    editHandler={handleEditPlayer}
                                    deleteHandler={openDeleteModal}
                                    theme={theme}
                                />
                            )
                        })
                        :
                        <></>
                    }
                </div>

                <div className={deleteModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                    <div className={deleteModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>
            
                        <p className={style.text} style={{color:'#EEE',textAlign:'center', margin:'8px'}}>{`Borrar el reproductor '${titleToDelete}'?`}</p>
                        <div className={style.modal_openclose_buttons}>
                            <button onClick={handleDeletePlayer}className={style.submit}>ACEPTAR</button>
                            <div className={style.close_button} onClick={closeDeleteModal}></div>
                        </div>

                    </div>
                </div>
                
                <div className={infoModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                    <div className={infoModal ? `${style.info_block} ${style.on_content}` : `${style.info_block} ${style.off_content}`}>
            
                        <p>Si se pega un video de youTube, en el código del iframe se debe reemplazar el width por <span style={{color:'orange'}}>"100%"</span> y el height por <span style={{color:'orange'}}>"200"</span>.</p>
                        <br></br>
                        <p>Se debe agregar el atributo estilo: <span style={{color:'orange'}}>style="border-radius:6px"</span></p>
                        <br></br>
                        <p>En caso de algún inconveniente, contactarse con el Admin.</p>

                        <div className={style.modal_openclose_buttons}>
                            <div className={style.close_button} onClick={()=>setInfoModal(false)}></div>
                        </div>

                    </div>
                </div>

                
                <div className={newPlayerModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                    
                    <div className={newPlayerModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                        
                        <form className={style.form} onSubmit={handleCreateNewPlayer}>
                            <div className={style.block}>
                                <p className={style.bold_text}>Título</p>
                                <input className={`${style.input} ${style.big_input}`} name="description" />
                            </div>

                            <div className={style.block}>
                                <p className={style.bold_text}>Contenido</p>
                                <textarea className={style.textarea} name="content" />
                            </div>
                            <button type="submit" className={style.submit}>aceptar</button>
                        </form>

                        <div className={style.close_button} onClick={()=>setNewPlayerModal(false)}></div>

                    </div>
                </div>
                
            </>
        }
    </MainLayOut>
    );
}

export default Players;
