import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const allPublicationsAPI = async (page, pubsPerPage, token) => {
    try {     
        const response = await fetch(`${apipath}/admin/publications?offset=${page}&limit=${pubsPerPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`,
            }
        });
        return await response.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar crear una nueva publicación: ${error}`
        }
    }
};

const getPublicationByIdToEditAPI = async (id, token) => {
    try {        
        const response = await fetch(`${apipath}/admin/publications/editbyid?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await response.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar crear una nueva publicación: ${error}`
        }
    }
};

const getAllPublicationsByParamAPI = async (param, value, offset, limit, token) => {
    try {        
        const response = await fetch(`${apipath}/admin/publications/byparam?param=${param}&value=${value}&limit=${limit}&offset=${offset}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await response.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar crear una nueva publicación: ${error}`
        }
    }
};

const getMylastEditedAPI = async (token) => {
    try {        
        const response = await fetch(`${apipath}/admin/lasteditions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await response.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las últimas publicaciones editadas: ${error}`
        }
    }
}

const newPublicationAPI = async (token) => {
    try {        
        const response = await fetch(`${apipath}/admin/publications`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await response.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar crear una nueva publicación: ${error}`
        }
    }
};

const updatePublicationAPI = async (data, id, token) => {
    try {        
        const updateTheme = await fetch(`${apipath}/admin/publications?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`,
            },
            body:  JSON.stringify(data)
        });
        return await updateTheme.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar guardar la preferenciaaaaaaaa: ${error}`
        }
    }
};

const uploadPublicationImagesAPI = async (data, imageName, id, folder, token) => {
    try {
        const upload = await fetch(`${apipath}/admin/upload/image/?id=${id}&imagename=${imageName}&folder=${folder}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            enctype: 'multipart/form-data',
            body: data
        });
        return await upload.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar la imagen:${error}`};
    }
};

const deleteImageFromServerAPI = async ( id, folder, fileName, param, token ) => {
    try {
        const data = { id, folder, fileName, param };
        const deletedImg = await fetch(`${apipath}/admin/upload/image`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await deletedImg.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const uploadPublicationImageGalleryAPI = async (data, id, folder, token) => {
    try {
        const upload = await fetch(`${apipath}/admin/upload/imagegallery/?publicationId=${id}&folder=${folder}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            enctype: 'multipart/form-data',
            body: data
        });
        return await upload.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar la imagen:${error}`};
    }
};

const deletePublicationImageGalleryAPI = async (id, token) => {
    try {
        const deleteImgGal = await fetch(`${apipath}/admin/upload/imagegallery/?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            enctype: 'multipart/form-data',
        });
        return await deleteImgGal.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo borrar la imagen de la galer®ia: ${error}`};
    }
};

const deletePublicationFromAPI = async (id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/publications?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },     
        });
        return await deleted.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar la imagen:${error}`};
    }
};

// ***********  AUDIO AND VIDEO GALLERY ********** //

const uploadPublicationAudioGalleryAPI = async ( id, data, token) => {
    try {
        const upload = await fetch(`${apipath}/admin/upload/audiogallery/?publicationId=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body:  JSON.stringify(data)
        });
        return await upload.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar el audio:${error}`};
    }
};

const deletePublicationAudioGalleryAPI = async ( id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/upload/audiogallery/?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await deleted.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar el audio:${error}`};
    }
};

const uploadPublicationAudioGalleryFileAPI = async (formData, id, position, title, description, folder, token) => {
    try {
        const upload = await fetch(`${apipath}/admin/upload/fileaudiogallery/?publicationId=${id}&position=${position}&title=${title}&description=${description}&folder=${folder}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            enctype: 'multipart/form-data',
            body: formData
        });
        return await upload.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar el audio:${error}`};
    }
};

const uploadPublicationVideoGalleryAPI = async ( id, data, token) => {
    try {
        const upload = await fetch(`${apipath}/admin/upload/videogallery/?publicationId=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body:  JSON.stringify(data)
        });
        return await upload.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar el audio:${error}`};
    }
};

const deletePublicationVideoGalleryAPI = async ( id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/upload/videogallery/?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await deleted.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar el audio:${error}`};
    }
};

const updateVideoGalleryAPI = async (data, id, token) => {
    try {
        const upload = await fetch(`${apipath}/admin/upload/videogallery/?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body:  JSON.stringify(data)
        });
        return await upload.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo actualizar el video: ${error}`};
    }
};

const updateAudioGalleryAPI = async (data, id, token) => {
    try {
        const upload = await fetch(`${apipath}/admin/upload/audiogallery/?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body:  JSON.stringify(data)
        });
        return await upload.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo actualizar el video: ${error}`};
    }
};


export {
    allPublicationsAPI,
    getPublicationByIdToEditAPI,
    getAllPublicationsByParamAPI,
    getMylastEditedAPI,
    newPublicationAPI,
    updatePublicationAPI,
    uploadPublicationImagesAPI,
    deleteImageFromServerAPI,
    uploadPublicationImageGalleryAPI,
    deletePublicationImageGalleryAPI,
    deletePublicationFromAPI,

    uploadPublicationAudioGalleryAPI,
    deletePublicationAudioGalleryAPI,
    uploadPublicationAudioGalleryFileAPI,

    uploadPublicationVideoGalleryAPI,
    deletePublicationVideoGalleryAPI,

    updateVideoGalleryAPI,
    updateAudioGalleryAPI,
};
