import { useContext, useEffect, useRef, useState } from 'react';

import { AddIcon, EditIcon, Loader } from '../../../assets';

import { AppContext, UserContext, ModalContext, ThemeContext } from '../../../contexts';

import { MainLayOut } from '../../../layouts';

import { useNavigateTo } from '../../../hooks';
import { privateRoutes } from '../../../routes';
import { error_type } from '../../../models';

import ToggleSwitch from '../toggleswitch';
import Listcard from './components/listcard';

import { updateSidebarContentAPI, getAllListsAPI, updateListsOrderAPI, createNewListAPI, updateListByIdAPI, deleteListByIdAPI } from '../../../services';


import style from './lists.module.scss';



const Lists = () => {

    const { user, deleteUser, token, deleteToken } = useContext(UserContext);
    const { arranges, setArranges } = useContext(AppContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);
    const [ goTo ] = useNavigateTo();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ lists, setLists ] = useState([]);


    const defaultArrangeState = () => { return arranges.filter(arrange => arrange.name === 'lists')[0] || {}; };
    const [ listsArrange, setListsArrange ] = useState(defaultArrangeState);

    const [ titleEdition, setTitleEdition ] = useState(false);

    const [ newListModal, setNewListModal ] = useState(false);

    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ idToDelete, setIdToDelete ] = useState(null);
    const [ titleToDelete, setTitleToDelete ] = useState(null);

    const dragCard = useRef(0);
    const draggedOverCard = useRef(0);
    const arrangeCardsTimer = useRef(null);


    const handleCreateNewList = (e) => {
        e.preventDefault();

        const image = e.target.image.value;
        const title = e.target.title.value;
        const link = e.target.link.value;

        if (!image || !title || !link) return;
        
        const data = {image, title, link, active: false};
        createNewListAPI(data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setLists(res.data);
            setNewListModal(false);
            e.target.image.value = null;
            e.target.title.value = null;
            e.target.link.value  = null;
            return;
        });
    };
    const updateListsOrder = (data) => {
        clearTimeout(arrangeCardsTimer.current)

        arrangeCardsTimer.current = setTimeout( () => {
            updateListsOrderAPI(data, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser();
                        deleteToken();
                        setIdToDelete(null);
                        activateNotification(`${res.message}`, 'error', true, 3800);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 3800);
                setLists(res.data);
                return;
            })
        }, 1300);
    };
    const handleSortCards = () => {
        const cardsClone = [...lists];
        const temp = cardsClone[dragCard.current];
        cardsClone[dragCard.current] = cardsClone[draggedOverCard.current];
        cardsClone[draggedOverCard.current] = temp;
        const sorted = cardsClone.map( (card, index) => {
            return { id: card.id, position: index };
        });
        updateListsOrder(sorted);
        setLists(cardsClone);
    };
    const openDeleteModal = (id, title) => {
        setIdToDelete(id);
        setTitleToDelete(title);
        setDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setIdToDelete(null);
        setDeleteModal(false);
    };
    const handleDeleteList = () => {
        deleteListByIdAPI(idToDelete, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    setDeleteModal(false);
                    activateNotification(`${res.message}`, 'error', true, 3400);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3400);
                setDeleteModal(false);
                setIdToDelete(null);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3400);
            setDeleteModal(false);
            setLists(res.data);
            setIdToDelete(null);
            return;
        })
    };
    const activateList = (id, status) => {
        const data = { active: status };

        updateListByIdAPI(id, data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            };
            const newList = [...lists];
            const activated = lists.findIndex( item => item.id === id);
            newList[activated].active = status;
            setLists(newList);
            activateNotification(`${res.message}`, 'ok', true, 3800);
            return;
        })
    };
    const editListContent = (id, data) => {

        updateListByIdAPI(id, data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    setIdToDelete(null);
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            };
            setLists(res.data);
            activateNotification(`${res.message}`, 'ok', true, 3800);
            return;
        })
    };


    const handleEditTitle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setListsArrange( prev => {
            const newData = {...prev};
            newData[name] = value;
            return newData;
        })
    };
    const cancelTitleEdition = () => {
        setTitleEdition(false);
        setListsArrange(defaultArrangeState); 
    };
    const handleChangeArrangeTitleStatus = (id, status) => {
        const data = {
            id,
            showTitle: status
        };
        updateSidebarContentAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setListsArrange( prev => {
                const newData = {...prev};
                newData.showTitle = status;
                return newData;
            });
            return;
        });
    };
    const handleChangeTitle = () => {
        const id = listsArrange.id;
        const data = {
            title: listsArrange.title
        };
        updateSidebarContentAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 3800);
            setTitleEdition(false);
            return;
        });
    };

    useEffect(() => {
        setIsLoading(true);
        getAllListsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                return;
            };
            setLists(res.data);
            setIsLoading(false);
        })
    },[]);




    return (
        <MainLayOut>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <>
                    <div className={style.title_container}>
                        <p className={style.main_title} style={{cursor: 'pointer', color:`${theme.text}`}} onClick={()=> goTo(privateRoutes.SIDEBAR)}>
                            Barra lateral
                            <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Lista de noticias</span>
                        </p>
                    </div>

                    <div className={style.block} style={{background: `${theme.background}`}} onClick={()=>setNewListModal(true)}>
                        <AddIcon width='22px' height='22px' fill={theme.strokeColor02} />
                        <p className={style.light_text} style={{color: `${theme.text}`}}>Agregar noticia</p>
                    </div>


                    <div className={style.block_subtitle} style={{color:`${theme.text}`}}>
                        Título general del bloque
                    </div>
                    <div className={style.title_block} style={{justifyContent:'flex-start',background: `${theme.background}`}}>
                        {
                            titleEdition
                            ?
                            <>
                                <input className={style.input} onChange={handleEditTitle} name="title" value={listsArrange.title || ''}/>
                                <div className={style.edit_buttons}>
                                    <button className={style.cancel} onClick={cancelTitleEdition}>cancelar</button>
                                    <button className={style.submit} onClick={handleChangeTitle}>aceptar</button>
                                </div>
                            </>
                            :
                            <div style={{display:'flex',flexDirection:'row',gap:'14px'}}>
                                <ToggleSwitch id={listsArrange.id} handleChange={handleChangeArrangeTitleStatus} status={listsArrange.showTitle}/>
                                <div className={style.title_and_icon} onClick={()=>setTitleEdition(true)}>
                                    <p onClick={()=>setTitleEdition(true)} className={style.light_text} style={{color: `${theme.text}`}} dangerouslySetInnerHTML={ {__html: listsArrange?.title || 'Sin Título. Click para editar'} } />
                                    <EditIcon width='22px' height='22px' fill={theme.strokeColor01}/>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={style.block_subtitle} style={{color:`${theme.text}`}}>Lista</div>
                    <div className={style.list_list}>
                        {
                            lists?.length
                            ?
                            lists.map( ({ id, image, title, link, active }, i) => {
                                return (
                                    <Listcard key={id} i={i} id={id} image={image} title={title} link={link} active={active} theme={theme} dragCard={dragCard} draggedOverCard={draggedOverCard} deleteHandler={openDeleteModal} sortHandler={handleSortCards} activateHandler={activateList} editHandler={editListContent} />
                                )
                            })
                            :
                            <></>
                        }
                    </div>

                    <div className={deleteModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                        <div className={deleteModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>
                
                            <p className={style.text} style={{color:'#EEE',textAlign:'center', margin:'8px'}}>{`Borrar el item '${titleToDelete}'?`}</p>
                            <div className={style.modal_openclose_buttons}>
                                <button onClick={handleDeleteList}className={style.submit}>ACEPTAR</button>
                                <div className={style.close_button} onClick={closeDeleteModal}></div>
                            </div>

                        </div>
                    </div>


                    <div className={newListModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                    
                    <div className={newListModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                        
                        <form className={style.form} onSubmit={handleCreateNewList}>
                            <div className={style.block}>
                                <p className={style.bold_text}>URL de la imágen</p>
                                <input className={`${style.input} ${style.big_input}`} name="image" />
                            </div>

                            <div className={style.block}>
                                <p className={style.bold_text}>Título de la nota</p>
                                <input className={`${style.input} ${style.big_input}`} name="title" />
                            </div>

                            <div className={style.block}>
                                <p className={style.bold_text}>URL de la publicación</p>
                                <input className={`${style.input} ${style.big_input}`} name="link" />
                            </div>

                            <button type="submit" className={style.submit}>aceptar</button>
                        </form>

                        <div className={style.close_button} onClick={()=>setNewListModal(false)}></div>

                    </div>
                </div>
                </>
            }
        </MainLayOut>
    );
}

export default Lists;
