import { ImageUploader, ImageDeleter } from '../../../../assets';

import style from './editor.module.scss';


const ImageAndEpigraph = ({ id, number, publication, imageCompletePath, uploadHandler, deleteHandler, epigraphName, updateData, theme}) => {


    return (
        <div className={style.image_epigraph} style={{background: `${theme.background}`}}>

            <div className={style.title_content}>
                <p className={style.bold_text} style={{fontSize:'15px',color: `${theme.text}`}}>
                    Imagen {number}
                </p>
                { 
                    publication[id] === null
                    ?
                    <ImageUploader id={id} handler={uploadHandler} />
                    :
                    <ImageDeleter id={id} imgCompletePath={imageCompletePath} handleDelete={deleteHandler} />
                }
            </div>
        
        
            <div className={style.title_epigraph}>                               
                <p className={style.bold_text} style={{fontSize:'15px',color: `${theme.text}`}}>
                    Epígrafe {number}
                </p>
                <textarea
                    name={epigraphName}
                    className={style.epigraph}
                    style={{background: `${theme.inputBackground}`}}
                    // placeholder={editPublication.title || ''}
                    value={publication[epigraphName] || ''}
                    maxLength={255}
                    onChange={updateData}
                />
            </div>

        </div>
    )
};

export default ImageAndEpigraph;
