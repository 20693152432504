import { createContext } from "react";
import { useSetGetDelete } from "../../hooks";
import { keys } from "../../models";

const MenuContect = createContext();

const MenuContextProvider = ({ children }) => {

    const [ components, saveComponents, deleteComponents ] = useSetGetDelete([], keys.COMPONENTS);
    const [ sections, saveSections, deleteSections ] = useSetGetDelete([], keys.SECTIONS);
    const [ categories, saveCategories, deleteCategories ] = useSetGetDelete([], keys.CATEGORIES);
    const [ tags, saveTags, deleteTags, ] = useSetGetDelete([], keys.TAGS);

    return (
        <MenuContect.Provider value={{

            components, saveComponents, deleteComponents,
            sections, saveSections, deleteSections,
            categories, saveCategories, deleteCategories,
            tags, saveTags, deleteTags,

        }}>
            { children }
        </MenuContect.Provider>
    )
};

export default MenuContect;
export { MenuContextProvider };
