const TagIcon = ({width='36px', height='36px', fill}) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 36 36" xmlSpace="preserve">
            <path fill={fill} d="M22.6,35L22.6,35L22.6,35c0.2-0.2,0.4-0.4,0.5-0.6l11.8-13.6c1.4-1.6,1.4-3.9,0-5.6L23.1,1.6
            c-0.1-0.2-0.3-0.4-0.4-0.5l0,0v0C21.9,0.4,21,0,20,0H3.8C1.7,0,0,1.7,0,3.8v28.3C0,34.3,1.7,36,3.8,36H20C21,36,21.9,35.6,22.6,35z
            M23.9,18c0-1.9,1.6-3.5,3.5-3.5c1.9,0,3.5,1.6,3.5,3.5s-1.6,3.5-3.5,3.5C25.5,21.5,23.9,19.9,23.9,18z"/>
        </svg>
    )
};

export default TagIcon;