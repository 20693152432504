import { useState, useEffect, useContext, useRef } from 'react';

import { AppContext, UserContext, MenuContext, ModalContext, ThemeContext } from '../../contexts';

import { MainLayOut } from '../../layouts';
import ComponentRow from './components/componentrow';
import { AddIcon } from '../../assets';
import { error_type } from '../../models';

import { createNewComponentAPI, getAllComponentsAPI, updateComponentAPI, deleteComponentAPI, arrangeComponentsAPI } from '../../services';

import style from './components.module.scss';


const Components = () => {

    const { user, token, deleteToken, deleteUser } = useContext(UserContext);
    const { setTotalPublications, setTotalPages, setAllHilightPublications, setCurrentPage } = useContext(AppContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);
    const { components, saveComponents } = useContext(MenuContext);


    const [ createModal, setCreateModal ] = useState(false);
    const [ editModal, setEditModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    

    const [ nameEdit, setNameEdit ] = useState('');
    const [ descriptionEdit, setDescriptionEdit ] = useState('');
    const [ idToEdit, setIdToEdit ] = useState(null);
    const [ idToDelete, setIdToDelete ] = useState(null);
    const [ nameToDelete, setNameToDelete ] = useState('');


    const dragRow = useRef(0);
    const draggedOverRow = useRef(0);
    const arrangeRowsTimer = useRef(null);


    const updateComponentsOrder = (data) => {
        activateNotification('Reordenando componentes', 'time', true);
        clearTimeout(arrangeRowsTimer.current);

        arrangeRowsTimer.current = setTimeout( () => {
            arrangeComponentsAPI(data, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(); deleteToken();
                        setTotalPublications(0);
                        setTotalPages(0);
                        setAllHilightPublications([]);
                        setCurrentPage(1);
                        // setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    // setIsLoading(false);
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 3000);
                saveComponents(res.data);
                // setIsLoading(false);
                return;
            })
        }, 900)
    };

    const handleSortRows = () => {
        const rowsClone = [...components];
        const temp = rowsClone[dragRow.current];
        rowsClone[dragRow.current] = rowsClone[draggedOverRow.current];
        rowsClone[draggedOverRow.current] = temp;
        const sorted = rowsClone.map( (row, index) => {
            return { id: row.id, position: index}
        })
        updateComponentsOrder(sorted);
        saveComponents(rowsClone);
    };
    const openEditModal = (id, name, description) => {
        setEditModal(true);
        setNameEdit(name);
        setDescriptionEdit(description);
        setIdToEdit(id);
    };
    const closeEditModal = () => {
        setEditModal(false);
        setNameEdit('');
        setDescriptionEdit('');
        setIdToEdit(null);
    };
    const openCreateModal = (id) => {
        setCreateModal(true);
    };
    const closeCreateModal = () => {
        setCreateModal(false);
    };
    const handleNewComponent = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const description = e.target.description.value;
        if (!name || !description) {
            activateNotification('Por favor, ingresá todos los datos', 'error', true, 4000);
            return;
        }
        const data = {
            name,
            description
        };
        createNewComponentAPI(data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const newContent = [...components, res.data];
            saveComponents(newContent);
            e.target.name.value = '';
            e.target.description.value = '';
            closeCreateModal();
            return;
        })

    };
    const handleEditComponent = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const description = e.target.description.value;
        const data = {
            name,
            description,
            id: idToEdit
        };
        updateComponentAPI(data, idToEdit, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            saveComponents(res.data);
            e.target.name.value = '';
            e.target.description.value = '';
            closeEditModal();
            return;
        })
    };
    const playPauseComponent = (id, param) => {
        const data = {
            active: param
        };
        updateComponentAPI(data, id, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            saveComponents(res.data);
            closeEditModal();
            return;
        })
    };
    const openDeleteModal = (id, name) => {
        setIdToDelete(id);
        setNameToDelete(name);
        setDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setIdToDelete(null);
        setDeleteModal(false);
    };
    const handleDeleteComponent = () => {
        deleteComponentAPI(idToDelete, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    setIdToDelete(null);
                    setDeleteModal(false);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const newData = components.filter( item => item.id !== idToDelete);
            saveComponents(newData);
            setIdToDelete(null);
            setDeleteModal(false);
            return;
        })
    };

    useEffect(() => {
        getAllComponentsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteToken(); deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            saveComponents(res.data);
            return;
        })
    },[]);

    
    return (
        <MainLayOut>
            <div className={style.title_container}>
                <p className={style.main_title} style={{color:`${theme.text}`}}>Componentes</p>
            </div>

            {
                user.role === "Admin" &&
                <div onClick={openCreateModal} className={style.block} style={{background: `${theme.background}`}}>
                    <AddIcon width='22px' height='22px' fill={theme.strokeColor02} />
                    <p className={style.light_text} style={{color: `${theme.text}`}}>Crear nuevo componente</p>
                </div>
            }

            <div className={style.sections_list}>
                {
                    components.map( (component, i) => {
                        return (
                            <ComponentRow key={component.id} i={i} component={component} editHandler={openEditModal} playPauseHandler={playPauseComponent} deleteHandler={openDeleteModal} theme={theme} user={user} dragRow={dragRow} draggedOverRow={draggedOverRow} handleSort={handleSortRows} />
                        )
                    })
                }
            </div>


            {
                user.role === 'Admin' &&
                <div className={createModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                    
                    <div className={createModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                        
                        <form className={style.form} onSubmit={handleNewComponent}>
                            <div className={style.block}>
                                <p className={style.bold_text}>Título</p>
                                <textarea className={`${style.textarea} ${style.big_input}`} name="name" />
                            </div>

                            <div className={style.block}>
                                <p className={style.bold_text}>Descripción</p>
                                <textarea className={style.textarea} name="description" />
                            </div>
                            <button type="submit" className={style.submit}>aceptar</button>
                        </form>

                        <div className={style.close_button} onClick={closeCreateModal}></div>

                    </div>
                </div>
            }

            <div className={editModal ? `${style.modal_edition} ${style.blo} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                
                <div className={editModal ? `${style.modal_edition_content} ${style.on_content}` : `${style.modal_edition_content} ${style.off_content}`}>
                    <form onSubmit={handleEditComponent} className={style.form}>
                        <div className={style.block}>
                            <p className={style.bold_text}>Título</p>
                            <textarea className={`${style.textarea} ${style.big_input}`} onChange={(e) => setNameEdit(e.target.value)} name="name" value={nameEdit || ""}/>
                        </div>

                        <div className={style.block}>
                            <p className={style.bold_text}>Descripción</p>
                            <textarea className={style.textarea} onChange={(e) => setDescriptionEdit(e.target.value)} name="description" value={descriptionEdit || ''} />
                        </div>
                        <button type="submit" className={style.submit}>guardar cambios</button>
                    </form>
                    <div className={style.close_button} onClick={closeEditModal}></div>
                
                </div>
            </div>


            <div className={deleteModal ? `${style.modal_edition} ${style.blo} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
            
                <div className={deleteModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>
                    {/* <p className={style.bold_text} style={{color:'#EEE'}}>Borrar la categoría con id {idToDelete}?</p> */}
                    <p className={style.bold_text} style={{color:'#EEE',textAlign: 'center',margin:'6px'}}>Borrar el componente "{nameToDelete}"?</p>
                    <div className={style.modal_openclose_buttons}>
                        {/* <button className={style.cancel}>canclar</button> */}
                        <button onClick={handleDeleteComponent} className={style.submit}>ACEPTAR</button>
                        <div className={style.close_button} onClick={closeDeleteModal}></div>
                    </div>
                </div>

            </div>

        </MainLayOut>
    )
};

export default Components;
