import { PlayIcon, PublicationIcon, HilightIcon } from '../../../assets';
import { privateRoutes } from '../../../routes';

import { useNavigateTo } from '../../../hooks';

import style from './card.module.scss';



const ArrangeCard = ({i, item, dragCard, draggedOverCard, handleSort, theme}) => {

    const [ goTo ] = useNavigateTo();

    const blocks = {
        players: {
            icon: PlayIcon,
            name: 'Reproductores',
            color: `${theme.strokeColor05}`,
            url: privateRoutes.PLAYERS
        },
        banners: {
            icon: HilightIcon,
            name: 'Banners',
            color: `${theme.strokeColor01}`,
            url: privateRoutes.BANNERS
        },
        lists: {
            icon: PublicationIcon,
            name: 'Lista de noticias',
            color: `${theme.strokeColor06}`,
            url: privateRoutes.LISTS
        },
    };
    const Icon = blocks[item.name].icon;
    const color = blocks[item.name].color;
    
    return (
        <div
            className={style.block} style={{background:`${theme.background}`}}
            draggable
            onDragStart={ () => dragCard.current = i}
            onDragEnter={ () => draggedOverCard.current = i}
            onDragEnd={handleSort}
            onDragOver={(e) => {e.preventDefault()} }
            onClick={() => goTo(blocks[item.name].url)}
        >

            <Icon width='29px' fill={color}/>    
            
            <p className={style.light_text} style={{color: `${theme.text}`}}>{blocks[item.name].name}</p>
            {
                item?.title
                ?
                <p className={`${style.small_light_text} ${style.card_title}`} style={{color: `${theme.text}`}} dangerouslySetInnerHTML={ {__html: item.title} } />
                :
                <p className={`${style.small_light_text} ${style.card_title}`} style={{color: `${theme.text}`}}>Sin título</p>
            }
        </div>         
    )
};

export default ArrangeCard;
