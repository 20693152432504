import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;



const getTotalViewsAPI = async (token) => {
    try {
        const viewsStatic = await fetch(`${apipath}/admin/views/all`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await viewsStatic.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las estadísticas de visitas ${error}`
        }
    }
};

const getYearViewsAPI = async (token) => {
    try {
        const viewsStatic = await fetch(`${apipath}/admin/views/year`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await viewsStatic.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las estadísticas de visitas ${error}`
        }
    }
};

const getMonthViewsAPI = async (token) => {
    try {
        const viewsStatic = await fetch(`${apipath}/admin/views/month`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await viewsStatic.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las estadísticas de visitas ${error}`
        }
    }
};

const getEveryMonthViews = async (token) => {
    try {
        const viewsStatic = await fetch(`${apipath}/admin/views/months`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await viewsStatic.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las estadísticas de visitas ${error}`
        }
    }
};

const getEveryDayViews = async (token) => {
    try {
        const viewsStatic = await fetch(`${apipath}/admin/views/days`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await viewsStatic.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las estadísticas de visitas ${error}`
        }
    }
};

const getWeekViewsAPI = async (token) => {
    try {
        const viewsStatic = await fetch(`${apipath}/admin/views/week`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await viewsStatic.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las estadísticas de visitas ${error}`
        }
    }
};

const getTodayViewsAPI = async (token) => {
    try {
        const viewsStatic = await fetch(`${apipath}/admin/views/today`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await viewsStatic.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener las estadísticas de visitas ${error}`
        }
    }
};

export { getTotalViewsAPI, getYearViewsAPI, getMonthViewsAPI, getEveryMonthViews, getEveryDayViews, getWeekViewsAPI, getTodayViewsAPI };