import { createContext } from 'react';
import { useSetGetDelete } from '../../hooks';
import { userModel, keys } from '../../models';


const UserContext = createContext();


const UserContextProvider = ({children}) => {

    const [ user, saveUser, deleteUser ] = useSetGetDelete(userModel, keys.USER);
    const [ token, saveToken, deleteToken ] = useSetGetDelete("", keys.TOKEN);

    return (
        <UserContext.Provider
            value={{
                user, saveUser, deleteUser,
                token, saveToken, deleteToken 
            }}
        >
            {children}
        </UserContext.Provider>
    )
};

export default UserContext;
export { UserContextProvider };
