import { useState } from 'react';
import config from '../../../../config/index.js';

import { TextEditor, ImageAndEpigraph } from './index.js';
import { EditIcon } from '../../../../assets/index.js';



const TextImageEpigraph = ({style, title, value, modifier, htmlModifier, param, theme, id, number, publication, imageCompletePath, uploadHandler, deleteHandler, audioHandler, deleteAudioHandler, videoHandler, deleteVideoHandler, updateGallery, epigraphName, updateData }) => {

    const [ mediaType, setMediaType ] = useState('');
    const [ audioType, setAudioType ] = useState('iframe');
    const [ audioFile, setAudioFile ] = useState(null);
    const [ showContent, setShowContent ] = useState(() => { return value ? true : false; });

    const [ editAudioState, setEditAudioState ] = useState({});
    const [ editVideoState, setEditVideoState ] = useState({});

    const setMediaEdition = (setter, type, id, state) => {
        setter( prev => {
            const newState = {...prev, [`${type}_${id}`]: state };
            return newState;
        })
    };
    
    
    const handleChangeMediaType = (e) => { setMediaType(e.target.value); };
    const handleChangeAudioType = (e) => { setAudioType(e.target.value); };
    
    const resetAllFields = () => {
        setMediaType('');
        setAudioType('');
        setAudioFile(null);
    };
    
    
    return (
        <div className={style.paragraph_divisor}>
            <div className={style.title_and_button}>
                <p className={style.quill_title} style={{color: `${theme.text}`}}>
                    {title}
                </p>
                <button className={style.submit} onClick={ () => setShowContent(!showContent)}>{showContent ? 'ocultar': 'mostrar contenido'}</button>
            </div>
            {
                showContent ?
                <>
                    <TextEditor  title={title} value={value} modifier={modifier} htmlModifier={htmlModifier} param={param} theme={theme} />
                    <ImageAndEpigraph id={id} number={number} publication={publication} imageCompletePath={imageCompletePath} uploadHandler={uploadHandler} deleteHandler={deleteHandler} epigraphName={epigraphName} updateData={updateData} theme={theme} />

                    {
                        publication?.audiogalleries?.map( audio => {
                            return (
                                <div key={audio.id} style={{margin:'20px 0'}}>
                                    {
                                        audio.position == number &&
                                        <div className={style.media_gallery} style={{background:`${theme.darkBackground}`}}>
                                            <div className={style.media_gallery_title_button}>
                                                <p className={style.text} style={{color: `${theme.text}`}}>{ audio.type === 'file' ? 'Archivo de audio' : audio.type === 'iframe' && 'Audio de Spotify'}</p>
                                                <button className={style.submit} onClick={() => deleteAudioHandler(audio.id)}>eliminar</button>
                                            </div>
                                            <div className={style.media_content}>
                                                {
                                                    audio?.type === 'iframe'
                                                    &&
                                                    <iframe
                                                        style={{border:'none',borderRadius:'12px'}}
                                                        src={`${audio.source}`}
                                                        width="100%"
                                                        height="250"
                                                        allowFullScreen
                                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                                        loading="lazy"
                                                    >
                                                    </iframe>
                                                }
                                                {
                                                    audio?.type === 'file' &&
                                                    <div style={{padding: '12px 0'}}>
                                                        <audio src={`${config.api_statics}/${audio.source}`} controls />
                                                    </div> 
                                                }
                                                {
                                                    editAudioState[`title_${audio.id}`]
                                                    ?
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        TÍTULO:&emsp;&emsp;
                                                        </p>
                                                        <input className={style.input} name='title' value={audio.title || '' } maxLength={255} onChange={(e)=>updateGallery(e, 'audiogalleries', audio.id)}></input>
                                                        <button className={style.cancel} onClick={()=>setMediaEdition(setEditAudioState, 'title', audio.id, false)}>ok</button>
                                                    </div>
                                                    :
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        TÍTULO&emsp;&emsp;
                                                        <span className={style.small_light_text} style={{color:`${theme.text}`}}>{audio.title}</span>
                                                        </p>
                                                        <div style={{cursor:'pointer'}} onClick={()=> setMediaEdition(setEditAudioState, 'title', audio.id, true)}>
                                                            <EditIcon width='22px' fill='#FF9900'/>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    editAudioState[`description_${audio.id}`]
                                                    ?
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        DESCRIPCIÓN&emsp;&emsp;
                                                        </p>
                                                        <input className={style.input} name='description' value={audio.description || ''} maxLength={255} onChange={(e)=>updateGallery(e, 'audiogalleries', audio.id)}></input>
                                                        <button className={style.cancel} onClick={()=>setMediaEdition(setEditAudioState, 'description', audio.id, false)}>ok</button>
                                                    </div>
                                                    :
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        DESCRIPCIÓN&emsp;&emsp;
                                                        <span className={style.small_light_text} style={{color:`${theme.text}`}}>{audio.description}</span>
                                                        </p>
                                                        <div style={{cursor:'pointer'}} onClick={()=> setMediaEdition(setEditAudioState, 'description', audio.id, true)}>
                                                            <EditIcon width='22px' fill='#FF9900'/>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        publication?.videogalleries?.map( video => {
                            return (
                                <div style={{margin:'20px 0'}}>
                                    {
                                        video.position == number &&
                                        <div key={video.id} className={style.media_gallery} style={{background:`${theme.darkBackground}`}}>
                                            <div className={style.media_gallery_title_button}>
                                                <p className={style.text} style={{color: `${theme.text}`}}>YouTube video</p>
                                                <div style={{display:'flex', gap:'12px'}}>
                                                    <button className={style.danger_submit} onClick={() => deleteVideoHandler(video.id)}>eliminar</button>
                                                </div>
                                            </div>
                                            <div className={style.media_content}>
                                                <iframe
                                                    width='100%'
                                                    height='50%' 
                                                    src={`https://www.youtube.com/embed/${video.source}`}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen>
                                                </iframe>
                                                {
                                                    editVideoState[`title_${video.id}`]
                                                    ?
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        TÍTULO:&emsp;&emsp;
                                                        </p>
                                                        <input className={style.input} name='title' value={video.title || '' } maxLength={255} onChange={(e)=>updateGallery(e, 'videogalleries',video.id)}></input>
                                                        <button className={style.cancel} onClick={()=>setMediaEdition(setEditVideoState, 'title',video.id, false)}>ok</button>
                                                    </div>
                                                    :
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        TÍTULO:&emsp;&emsp;
                                                        <span className={style.small_light_text} style={{color:`${theme.text}`}}>{video.title}</span>
                                                        </p>
                                                        <div style={{cursor:'pointer'}} onClick={()=> setMediaEdition(setEditVideoState, 'title', video.id, true)}>
                                                            <EditIcon width='22px' fill='#FF9900'/>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    editVideoState[`description_${video.id}`]
                                                    ?
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        DESCRIPCIÓN:&emsp;&emsp;
                                                        </p>
                                                        <input className={style.input} name='description' value={video.description || ''} maxLength={255} onChange={(e)=>updateGallery(e, 'videogalleries',video.id)}></input>
                                                        <button className={style.cancel} onClick={()=>setMediaEdition(setEditVideoState, 'description', video.id, false)}>ok</button>
                                                        
                                                    </div>
                                                    :
                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-start',alignItems:'center',gap:'12px'}}>
                                                        <p className={style.small_bold_text} style={{color:'#FF6000'}}>
                                                        DESCRIPCIÓN:&emsp;&emsp;
                                                        <span className={style.small_light_text} style={{color:`${theme.text}`}}>{video.description}</span>
                                                        </p>
                                                        <div style={{cursor:'pointer'}} onClick={()=> setMediaEdition(setEditVideoState, 'description', video.id, true)}>
                                                            <EditIcon width='22px' fill='#FF9900'/>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }

                    <div style={{margin:'20px 0',padding:'20px 0', display:'flex',flexDirection:'column',gap:'12px'}}>
                            <p className={style.bold_text} style={{color: `${theme.text}`}}>
                                Insertar Multimedia
                            </p>
                            <select className={`${style.selector} ${style.max_selector}`} onChange={handleChangeMediaType}>
                                <option value=''>seleccionar</option>
                                <option value='video'>Video de youtube</option>
                                <option value='audio'>Audio</option>
                            </select>

                        {
                            mediaType === 'audio' && 
                            <form className={style.spotify_form} style={{width:'100%'}} onSubmit={(e) => {audioHandler(e, number, audioFile)} }>
                                <select className={`${style.selector} ${style.max_selector}`} name='type' onChange={handleChangeAudioType}>
                                    <option value='iframe'>spotify</option>
                                    <option value='file'>archivo de audio</option>
                                </select>
                                <input className={style.input} type='text' maxLength={255} name='title' placeholder="Título opcional del audio" />
                                {
                                    audioType === 'iframe' &&
                                    <textarea
                                        style={{background: `${theme.inputBackground}`}}
                                        className={style.textarea}
                                        name='source'
                                        placeholder='src de iframe de spotify'
                                    />
                                }
                                {
                                    audioType === 'file' &&
                                    <input
                                        className={style.input_upload}
                                        type="file"
                                        name="audio"
                                        accept="audio/mp3, audio/wav, audio/aac, audio/mpeg"
                                        onChange={(e)=>setAudioFile(e.target.files[0])}
                                    />
                                }
                                <input className={style.input} type='text' maxLength={255} name='description' placeholder="Descripción opcional del audio" />
                                <button className={style.submit} type="submit">crear nueva entrada</button>
                            </form>
                        }

                        {
                            mediaType === 'video' &&
                            <form className={style.spotify_form} style={{width:'100%'}} onSubmit={(e) => videoHandler(e, number)}>
                                <input className={style.input} type='text' maxLength={255} name='title' placeholder="Título opcional del video" />
                                <textarea
                                    style={{background: `${theme.inputBackground}`}}
                                    className={style.textarea}
                                    name='source'
                                    placeholder='Link de youtube después de https://youtube.com/watch?v='
                                />
                                <input className={style.input} type='text' maxLength={255} name='description' placeholder="Descripción opcional del video" />
                                <button className={style.submit} type="submit">crear nueva entrada</button>
                            </form>
                        }
                    </div>
                </>
                :
                <></>
            } 
        </div>
    );
}

export default TextImageEpigraph;
