import { EditIcon, PlayIcon, PauseIcon, DeleteIcon } from '../../../assets';

import style from './row.module.scss';


const TagRow = ({i, tag, editHandler, playPauseHandler, deleteHandler, theme, user }) => {
    return (
        <div className={style.main_row_content} style={{background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}>
            <p style={{width:'10px',height:'10px',display:'grid',placeContent:'center',borderRadius:'50%',color: `${theme.primary}`,backgroundColor: `${theme.primary}`}}>*</p>
            <div className={style.section_row}>
                
                <div className={style.section_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.section_title}>
                            <p className={style.big_bold_text} style={{color:`${theme.text}`}}>{tag.name}</p>
                        </div>
                    </div>
                    <div className={style.id_title}>
                        <div className={style.section_description}>
                            <p className={style.light_text} style={{color:`${theme.text}`}}>{tag.description}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>
                        <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{tag.createdAt.split('T')[0]}</p>
                    </div>

                </div>
                
                <div className={style.pub_icons}>
                
                    <div onClick={ () => editHandler(tag.id, tag.name, tag.description) } style={{cursor: 'pointer'}}>
                        <EditIcon width='19px' height='19px' fill={theme.alttext} />
                    </div>
                    {/* {
                        tag.active ?
                        <div onClick={() => playPauseHandler(tag.id, false)} style={{cursor: 'pointer'}}>
                            <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                        :
                        <div onClick={() => playPauseHandler(tag.id, true)} style={{cursor: 'pointer'}}>
                            <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                    } */}
                    {
                        user.role === 'Admin'
                        &&
                        <div onClick={() => deleteHandler(tag.id)} style={{cursor: 'pointer'}}>
                            <DeleteIcon width='17px' fill={theme.primary}/>
                        </div>
                    }
                </div>
              
            </div>
        </div>
    )
};

export default TagRow;
