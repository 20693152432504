import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const getAllCategoriesAPI = async (token) => {
    try {
        const allCategories = await fetch(`${apipath}/admin/categories/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
        })
        return await allCategories.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const createNewCategoryAPI = async (data, token) => {
    try {
        const newCategory = await fetch(`${apipath}/admin/categories/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await newCategory.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
}

const updateCategoryAPI = async (data, id, token) => {
    try {
        const updated = await fetch(`${apipath}/admin/categories?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await updated.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const deleteCategoryAPI = async (id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/categories?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        })
        return await deleted.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};

const arrangeCategoriesAPI = async (data, token) => {
    try {
        const arranged = await fetch(`${apipath}/admin/categories/arrange`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        })
        return await arranged.json();
    }
    catch (error) {
        return {
            error: true,
            message: `No se pudo realizar la operación por un error en el servidor ${error}`
        }
    };
};



export { createNewCategoryAPI, getAllCategoriesAPI, updateCategoryAPI, deleteCategoryAPI, arrangeCategoriesAPI };