import style from './editor.module.scss';


const Tag = ({name, handler, theme}) => {
    return (
        <div className={style.tag} style={{background: `${theme.invertedbackground}`}}>
            <p style={{color: `${theme.invertedtext}`}}>{name}</p>
            <div className={style.tag_delete} onClick={() => handler(name)}></div>
        </div>
    )
};

export default Tag;
