import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const getTagByNameAPI = async (name, token) => {
    try {
        const tag = await fetch(`${apipath}/admin/tags/byname?name=${name}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
        });
        return await tag.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

const getAllTagsAPI = async (token) => {
    try {
        const tag = await fetch(`${apipath}/admin/tags`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
        });
        return await tag.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

const createNewTagAPI = async (data, token) => {
    try {
        const newTag = await fetch(`${apipath}/admin/tags`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await newTag.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
}; 

const updateTagAPI = async (data, id, token) => {
    try {
        const newTag = await fetch(`${apipath}/admin/tags?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await newTag.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

const deleteTagAPI = async (id, token) => {
    try {
        const deletedTag = await fetch(`${apipath}/admin/tags?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await deletedTag.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error al intentar borrar la etiqueta ${error}`
        }
    }
};

export {
    getTagByNameAPI,
    createNewTagAPI,
    getAllTagsAPI,
    updateTagAPI,
    deleteTagAPI,
};
