const EndQuotes = ({width, fill}) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 51.3 40" xmlSpace="preserve" width={width} fill={fill}>
            <g>
                <path d="M51.3,22.7C50.9,33.5,42.8,39,32.9,40l-3-6.9c3.8-0.7,10.6-3.2,10.2-10.4H27.6V0l23.7,0V22.7z"/>
                <path d="M0,0l23.7,0v22.7C23.3,33.5,15.2,39,5.3,40l-3-6.9c3.8-0.7,10.6-3.2,10.2-10.4H0L0,0z"/>
            </g>
        </svg>
    );
}

export default EndQuotes;
