import { useState, useEffect, useContext } from 'react';
import { UserContext, AppContext, ModalContext, ThemeContext } from '../../contexts';

import { MainLayOut } from "../../layouts";
import { Circle, Loader } from "../../assets";

import PublicationRow from './components/pubrow';

import { useNavigateTo } from '../../hooks';

import { privateRoutes } from '../../routes';
import { getYearViewsAPI, getMonthViewsAPI, getWeekViewsAPI, getTodayViewsAPI, getMylastEditedAPI, updatePublicationAPI } from '../../services';
import { error_type } from '../../models';

import style from './dashboard.module.scss';



const Dashboard = () => {

    const { allPublications, setAllPublications, setIdEditPublication, setEditPublication } = useContext(AppContext);
    const { user, token, deleteUser, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);

    const [ goto ] = useNavigateTo()

    const [ yearVisits, setYearVisits ] = useState(0);
    const [ monthVisits, setMonthVisits ] = useState(0);
    const [ weekVisits, setWeekVisits ] = useState(0);
    const [ last7Days, setLast7Days ] = useState(0);
    const [ last30Days, setLast30Days ] = useState(0);
    const [ todayVisits, setTodayVisits ] = useState(0);

    const [ isLoading, setIsLoading ] = useState(false);



    const handleEditPublicationById = (id) => {
        setIdEditPublication(id);
        setEditPublication({});
        goto(privateRoutes.EDITPUBLICATION);
    };

    const handlePlayPausePublication = (id, status) => {
        const data = { status };
        updatePublicationAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const updatedState = allPublications.map( pub => {
                if ( pub.id === id ) {
                    return {...pub, status }
                };
                return pub;
            });
            setAllPublications(updatedState);
            return;
        })
    };

    const handleGetLastEdited = () => {
        getMylastEditedAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    setIsLoading(false);
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 5000);
                setIsLoading(false);
                return;
            };
            setAllPublications(res.data);
            setIsLoading(false);
            return;
        })
    };

 
    useEffect( () => {
        handleGetLastEdited();
        getYearViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setYearVisits(res.data);
            return;
        });
        getMonthViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setMonthVisits(res.data);
            return;
        });
        getWeekViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setWeekVisits(res.data.lastWeek);
            setLast7Days(res.data.lastSeven);
            setLast30Days(res.data.lastThirty);
            return;
        });
        getTodayViewsAPI(token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            setTodayVisits(res.data);
            return;
        });
    },[]);


    return (

        <MainLayOut>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <>
                    <div className={style.title_container}>
                    <p className={style.main_title} style={{color:`${theme.text}`}}>Dashboard</p>
                    </div>

                    <div className={style.blocks_container}>
                        <p className={style.light_subtitle} style={{color:`${theme.text}`}}>Estadísticas generales</p>
                    </div>

                    <div className={style.blocks_container}>
                        {/* ********** AÑO Y MES ************** */}
                        
                   

                        <div className={style.block} style={{background:`${theme.background}`}}>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        100%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor01} value='100'/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas del año:
                                    <span className={style.mid_bold_text}> {yearVisits}</span>
                                </p>
                            </div>

                            
                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        {Math.floor(monthVisits * 100 / yearVisits)}%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor02} value={Math.round(monthVisits * 100 / yearVisits)}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas mensuales:
                                    <span className={style.mid_bold_text}> {monthVisits}</span>
                                </p>
                            </div>

                        </div>
                      
                        {/* ********** MES Y SEMANA *********** */}
                        <div className={style.block} style={{background:`${theme.background}`}}>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        100%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor01} value={100}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Últimos 30 días:&emsp;
                                    <span className={style.mid_bold_text}>{last30Days}</span>
                                </p>
                            </div>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        {Math.floor(last7Days * 100 / last30Days) || 0}%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor03} value={Math.floor(last7Days * 100 / last30Days)}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Últimos 7 días:&emsp;
                                    <span className={style.mid_bold_text}>{last7Days}</span>
                                </p>
                            </div>

                        </div>

                        {/* ********** SEMANA Y DIA *********** */}
                        <div className={style.block} style={{background:`${theme.background}`}}>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        100%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor01} value={100}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas semanales:&emsp;
                                    <span className={style.mid_bold_text}>{weekVisits}</span>
                                </p>
                            </div>

                            <div className={style.dual_data}>
                                <div className={style.data_circle}>
                                    <p className={`${style.data} ${style.bold_text}`} style={{color:`${theme.text}`}}>
                                        {Math.floor(todayVisits * 100 / weekVisits) || 0}%
                                    </p>
                                    <div className={style.circle}>
                                        <Circle width='90px' heigth='90px' strokeWidth={2.5} strokeColor={theme.strokeColor04} value={Math.floor(todayVisits * 100 / weekVisits)}/>
                                    </div>
                                </div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                                    Visitas diarias:&emsp;
                                    <span className={style.mid_bold_text}>{todayVisits}</span>
                                </p>
                            </div>

                        </div>

                    </div>



                    <div className={style.blocks_container}>
                        <p className={style.light_subtitle} style={{color:`${theme.text}`}}>Tu última actividad</p>
                    </div>
                    
                    <div className={style.blocks_container}>

                        <div className={style.block} style={{display:'flex',flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start',background:`${theme.background}`}}>
                            <p className={style.big_bold_text} style={{padding: '22px 0',color:`${theme.text}`}}>
                                Últimas ediciones:
                            </p>
                            {
                                allPublications.map( (publication, i) => {
                                    return (
                                        <PublicationRow key={publication.id} i={i} publication={publication} theme={theme} edithandler={handleEditPublicationById} playPauseHandler={handlePlayPausePublication} user={user} />
                                    )
                                })
                            }
                        </div>
                        
                    </div>
                </>
            }

        </MainLayOut>
    )
};

export default Dashboard;

{/* <div className={style.block_circle}>{visitas.diarias} - {Math.round((visitas.diarias * 100) / visitas.mensuales)} %</div> */}
                    // <div>{visitas.semanales} - {Math.round((visitas.semanales * 100) / visitas.mensuales)} %</div>