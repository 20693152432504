import { useState } from 'react';

import ToggleSwitch from '../../toggleswitch';

import { EditIcon, DeleteIcon } from '../../../../assets';

import style from '../banners.module.scss';


const Bannercard = ( { i, id, path, image, link, active, deleteHandler, theme, dragCard, draggedOverCard, sortHandler, activateHandler, editHandler } ) => {

    const [ isEditing, setIsEditing ] = useState(false);
    const [ linkContent, setLinkContent ] = useState(link);

    const cancelEditing = () => {
        setIsEditing(false);
        setLinkContent(link);
    };

    const editLink = () => {
        editHandler(id, linkContent)
        setIsEditing(false);
    };

    return (
        <div
            key={id}
            className={style.banner_card}
            style={{background:`${theme.background}`}}
            draggable
            onDragStart={ () => dragCard.current = i}
            onDragEnter={ () => draggedOverCard.current = i}
            onDragEnd={sortHandler}
            onDragOver={(e) => {e.preventDefault()}}
        >
            <a href={link} target="_blank">
                <img className={style.banner_image} src={`${path}/banners/${image}`}/>
            </a>


            <div className={style.edit_icons}>
                <ToggleSwitch id={id} handleChange={activateHandler} status={active}/>

                <div className={style.edit_icon} onClick={()=> setIsEditing(true)}>
                    <EditIcon height='20px' fill={`${theme.text}`} />
                    <p style={{color:`${theme.text}`}}>editar</p>
                </div>
    
                <div className={style.edit_icon} style={{background:`${theme.background}`,gap:'6px'}} onClick={()=> deleteHandler(id)}>
                    <DeleteIcon width='18px' fill={`${theme.text}`}/>
                    <p style={{color:`${theme.text}`}}>borrar</p>
                </div>
            </div>
            
            {
                isEditing
                ?
                <div className={style.edit_form} style={{color:`${theme.text}`}}>
                    <input className={style.input} value={linkContent || ''} onChange={(e)=>setLinkContent(e.target.value)}/>
                    <div style={{display:'flex',flexDirection:'row',gap:'12px'}}>
                        <button className={style.cancel} onClick={cancelEditing}>cancelar</button>
                        <button className={style.submit} onClick={editLink}>aceptar</button>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    );
}

export default Bannercard;
