import config from '../../../../config';
import { EditIcon, PlayIcon, PauseIcon, DeleteIcon } from '../../../../assets';

import { localDate, localDateAndTime } from '../../../../helpers';

import style from './row.module.scss';


const PublicationRow = ({i, publication, theme, edithandler, playPauseHandler, user}) => {
    
    return (
        <div className={style.main_row_content} style={{background: i % 2 === 0 ? `${theme.darkBackground}` : `${theme.background}` }}>
            <p className={`${style.pub_id} ${style.small_bold_text}`} style={ publication.status === 'active' ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`} }>{publication.id}</p>
            <div className={style.thumbnail} style={{backgroundImage:`url(${config.api_statics}/${publication?.folder}/${publication?.coverImage})`}}></div>
            <div className={style.pub_row}>
                
                <div className={style.pub_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.pub_title}>
                            <p className={style.light_text} style={{color:`${theme.text}`,fontSize:'15px'}}>{publication?.title ? publication?.title : 'sin título'}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>
                        
                        <div>
                            <p className={style.small_light_text} style={{color:`${theme.text}`}}>creada el:</p>
                            <p className={style.small_light_text} style={{color:`${theme.primary}`}}>
                                { publication?.createdAt.split('T')[0].split('-').reverse().join('/')}
                                {/* { publication?.createdAt.split('T')[1].split(':').slice(0,2).join(':')} hs. */}
                            </p>
                        </div>
                        
                        <div>
                            <p className={style.small_light_text} style={{color:`${theme.text}`}}>activa el:</p>
                            <p className={style.small_light_text} style={{color:`${theme.primary}`}}>
                                {
                                    publication?.availability &&
                                    localDateAndTime(publication?.availability)
                                }
                            </p>
                        </div>

                        <div>
                            <p className={style.small_light_text} style={{color:`${theme.text}`}}>creado por</p>
                            <p className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.creator?.firstName}</p>
                        </div>

                        {
                            publication?.editor
                            &&
                            <div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>editado por</p>
                                <p className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.editor?.firstName}</p>
                            </div>
                        }
                    </div>

                </div>
                
                <div className={style.pub_icons}>
                
                    <div onClick={() => edithandler(publication?.id)} style={{cursor: 'pointer'}}>
                        <EditIcon width='19px' height='19px' fill={theme.alttext} />
                    </div>
                    {
                        publication?.status === 'pending' ?
                        <div onClick={() => playPauseHandler(publication?.id, 'active')} style={{cursor: 'pointer'}}>
                            <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                        :
                        <div onClick={() => playPauseHandler(publication?.id, 'pending')} style={{cursor: 'pointer'}}>
                            <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                    }
                    {/* {
                        user.role === 'Admin' &&
                        <div onClick={() => deleteHandler(publication.id)} style={{cursor: 'pointer'}}>
                            <DeleteIcon width='17px' fill={theme.primary}/>
                        </div>
                    } */}
                </div>
              
            </div>
        </div>
    )
};

export default PublicationRow;
