import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const getSidebarArrangementAPI = async (token) => {
    try {
        const order = await fetch(`${apipath}/admin/sidebar/arrangement`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
        });
        return await order.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

const updateSidebarContentAPI = async (data, id, token) => {
    try {
        const reorder = await fetch(`${apipath}/admin/sidebar/arrange?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await reorder.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

const arrangeSidebarContentAPI = async (data, token) => {
    try {
        const reorder = await fetch(`${apipath}/admin/sidebar/arrangement`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await reorder.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

// ************ PLAYERS ************ //

const getAllPlayersAPI = async (token) => {
    try {
        const players = await fetch(`${apipath}/admin/sidebar/players`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await players.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

const createNewPLayerAPI = async (data, token) => {
    try {
        const newPlayer = await fetch(`${apipath}/admin/sidebar/players`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data),
        });
        return await newPlayer.json();
    }
    catch (error) {
        return { error: true, message: `Ocurrió un error al intentar crear un nuevo reproductor ${error}` };
    }
};

const updatePlayerByIdAPI = async (data, id, token) => {
    try {
        const updated = await fetch(`${apipath}/admin/sidebar/players?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await updated.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar editar el banner: ${error}`};
    }
};

const updatePlayersOrderAPI = async (data, token) => {
    try {
        const reorder = await fetch(`${apipath}/admin/sidebar/players/arrange`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await reorder.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar reordenar los reproductores de la barra lateral: ${error}`};
    }
};

const deletePlayerByIdAPI = async (id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/sidebar/players?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await deleted.json();
    }
    catch (error) {
        return { error: true, message: `Ocurrió un error al intentar borrar el reproductor: ${error}` };
    }
};



// ************ BANNERS ************ //

const uploadBannerAPI = async (data, token) => {
    try {
        const uploadBanner = await fetch(`${apipath}/admin/sidebar/banners`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            enctype: 'multipart/form-data',
            body: data
        });
        return await uploadBanner.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar la imagen:${error}`};
    }
};

const getAllBannersAPI = async (token) => {
    try {
        const banners = await fetch(`${apipath}/admin/sidebar/banners`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await banners.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas las etiquetas: ${error}`};
    }
};

const updateBannersOrderAPI = async (data, token) => {
    try {
        const reorder = await fetch(`${apipath}/admin/sidebar/banners/arrange`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await reorder.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar reordenar los banners de la barra lateral: ${error}`};
    }
};

const updateBannerByIdAPI = async (id, data, token) => {
    try {
        const updated = await fetch(`${apipath}/admin/sidebar/banners?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await updated.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar editar el banner: ${error}`};
    }
};

const deleteBannerByIdAPI = async (id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/sidebar/banners?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await deleted.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar borrar el banner: ${error}`};
    }
};


// *********** LISTS ************ //

const getAllListsAPI = async (token) => {
    try {
        const lists = await fetch(`${apipath}/admin/sidebar/lists`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await lists.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar obtener todas la lista de publicaciones: ${error}`};
    }
};

const updateListsOrderAPI = async (data, token) => {
    try {
        const reorder = await fetch(`${apipath}/admin/sidebar/lists/arrange`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await reorder.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar reordenar los banners de la barra lateral: ${error}`};
    }
};

const createNewListAPI = async (data, token) => {
    try {
        const newList = await fetch(`${apipath}/admin/sidebar/lists`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data),
        });
        return await newList.json();
    }
    catch (error) {
        return { error: true, message: `Ocurrió un error al intentar crear un nuevo item de la lista ${error}` };
    }
};

const updateListByIdAPI = async (id, data, token) => {
    try {
        const updated = await fetch(`${apipath}/admin/sidebar/lists?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await updated.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar editar el banner: ${error}`};
    }
};

const deleteListByIdAPI = async (id, token) => {
    try {
        const deleted = await fetch(`${apipath}/admin/sidebar/lists?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await deleted.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error al intentar borrar el item de la lista: ${error}`};
    }
};


export {
    getSidebarArrangementAPI,
    updateSidebarContentAPI,
    arrangeSidebarContentAPI,

    getAllPlayersAPI,
    createNewPLayerAPI,
    updatePlayerByIdAPI,
    updatePlayersOrderAPI,
    deletePlayerByIdAPI,

    uploadBannerAPI,
    getAllBannersAPI,
    updateBannerByIdAPI,
    updateBannersOrderAPI,
    deleteBannerByIdAPI,

    getAllListsAPI,
    updateListsOrderAPI,
    createNewListAPI,
    updateListByIdAPI,
    deleteListByIdAPI,
};
