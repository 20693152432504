import { useState } from 'react';
import ToggleSwitch from '../../toggleswitch';

import { EditIcon, DeleteIcon } from '../../../../assets';

import style from '../players.module.scss';


const Playercard = ({ i, id, content, description, active, activateHandler, dragCard, draggedOverCard, sortHandler, editHandler, deleteHandler, theme} ) => {

    const player = () => { return {__html: content} };

    const [ isEditing, setIsEditing ] = useState(false);
    const [ editionContent, setEditionContent ] = useState({
        description,
        content
    });

    const editContent = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEditionContent( prev => {
            const newData = {...prev};
            newData[name] = value;
            return newData;
        })
    }

    const cancelEditing = () => {
        setEditionContent({description, content})
        setIsEditing(false);
    };

    const editPlayer = () => {
        editHandler(editionContent, id)
        setIsEditing(false);
    };


    return (
        <div
            key={id}
            className={style.players_card}
            style={{background:`${theme.background}`}}
            draggable
            onDragStart={ () => dragCard.current = i}
            onDragEnter={ () => draggedOverCard.current = i}
            onDragEnd={sortHandler}
            onDragOver={(e) => {e.preventDefault()}}
        >
            <div className={style.text} style={{color:`${theme.text}`}}>{description}</div>
            <div dangerouslySetInnerHTML={player()} />

            <div className={style.edit_icons}>
                <ToggleSwitch id={id} handleChange={activateHandler} status={active}/>

                <div className={style.edit_icon} onClick={()=> setIsEditing(true)}>
                    <EditIcon height='20px' fill={`${theme.text}`} />
                    <p style={{color:`${theme.text}`}}>editar</p>
                </div>
    
                <div className={style.edit_icon} style={{background:`${theme.background}`,gap:'6px'}} onClick={()=> deleteHandler(id, description)}>
                    <DeleteIcon width='18px' fill={`${theme.text}`}/>
                    <p style={{color:`${theme.text}`}}>borrar</p>
                </div>
            </div>
            {
                isEditing
                ?
                <div className={style.edit_form}>

                    <div className={style.edit_block}>
                        <p className={style.text}>Título</p>
                        <input className={style.input} onChange={(e)=>editContent(e)} name="description" value={editionContent.description || ''} />
                    </div>
                
                    <div className={style.edit_block}>
                        <p className={style.text}>Contenido</p>
                        <textarea className={style.textarea} onChange={(e)=>editContent(e)} name="content" value={editionContent.content || ''} />
                    </div>
        
        
                    <div className={style.edit_buttons}>
                        <button className={style.cancel} onClick={cancelEditing}>cancelar</button>
                        <button className={style.submit} onClick={editPlayer}>aceptar</button>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    );
}

export default Playercard;
