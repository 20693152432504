// import loader from '../../images/assets/loader.svg';
import style from './loader.module.scss';

const SmallLoader = ( { width = 60 } ) => {
    return (
        <div className={style.loader} style={{width:`${width}px`,height:`${width}px`}}></div>
    );
}

export default SmallLoader;
