import config from '../../../../../config';
import { AddIcon, HilightIcon } from '../../../../../assets';

import style from './row.module.scss';


const PublicationRow = ({i, publication, idHilights, addHandler, theme}) => {


    return (
        <div className={style.main_row_content} style={{background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}>
            <p className={`${style.pub_id} ${style.small_bold_text}`} style={ publication.status === 'active' ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`} }>{publication.id}</p>
            <div className={style.thumbnail} style={{backgroundImage:`url(${config.api_statics}/${publication.folder}/${publication.coverImage})`}}></div>
            <div className={style.pub_row}>
                
                <div className={style.pub_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.pub_title}>
                            <p className={style.light_text} style={{color:`${theme.text}`}}>{publication.title ? publication.title : 'sin título'}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>
                        <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{publication.createdAt.split('T')[0]}</p>
                        <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                            creado por
                            <span className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.creator?.firstName}</span>
                        </p>
                        <p className={style.small_light_text} style={{color:`${theme.text}`}}>
                            editado por
                            <span className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.editor?.firstName}</span>
                        </p>
                    </div>

                </div>
                
                <div className={style.pub_icons}>

                    {
                        idHilights.includes(publication.id)
                        ?
                        <HilightIcon width='23px' height='23px' fill={theme.strokeColor04} />
                        :
                        publication.status === 'active' && idHilights.length < 6
                        ?
                        <div onClick={() => addHandler(publication.id)} style={{cursor: 'pointer'}}>
                            <AddIcon width='19px' height='19px' fill={theme.strokeColor02} />
                        </div>
                        :
                        <></>
                    }
                    
                </div>
              
            </div>
        </div>
    )
};

export default PublicationRow;
